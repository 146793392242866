<template>
    <div class="notifications-overview">
        <div class="notifications-overview__container">
            <pui-headline type="h2" class="notifications-overview__title"> {{ $t('notificationsTitle') }} </pui-headline>
            <pui-tabs
                v-if="notificationsLoaded && showTabs"
                :selected="selectedTab"
                @changed="onTabChanged"
                class="notifications-overview__tabs">
                <pui-action-bar
                    v-if="selectedTab === 0"
                    :actions="actionItemsTaskNotis"
                    :is-inline="true"
                    :forceCollapse="false"
                    @clicked:action="markAllNotificationsAsRead"
                    slot="action-bar"
                />
                <pui-action-bar
                    v-if="selectedTab === 1"
                    :actions="actionItemsDisplayNotis"
                    :is-inline="true"
                    :forceCollapse="false"
                    @clicked:action="setShowClearNotisToast(true)"
                    slot="action-bar"
                />
                <div slot="default" class="notifications-overview__filter">
                    <pui-tooltip />
                    <pui-filter
                        :use-router="false"
                        :config="{
                            filters: getMultiselectFilters()
                        }"
                        @changed:applied-values="filterValueChanged"
                        vuex-namespace="ns2"
                    />
                </div>
                <pui-tab :title="`Actions (${this.tasksTotalCount})`">
                    <notifications-tab
                        :notifications="tasks"
                        :totalPages="Math.ceil(tasksTotalCount / defaultPageSize)"
                        :shouldBeDisabled="false"
                        :currentPage="currentPage"
                        @onPageChanged="handlePageChanged($event, tabTypes.Tasks)"
                    />
                </pui-tab>
                <pui-tab :title="`Notifications (${simpleNotificationsTotalCount})`">
                    <notifications-tab
                        :notifications="simpleNotifications"
                        :totalPages="Math.ceil(simpleNotificationsTotalCount / defaultPageSize)"
                        :shouldBeDisabled="false"
                        :currentPage="currentPage"
                        @onPageChanged="handlePageChanged($event, tabTypes.Notifications)"
                    />
                </pui-tab>
                <pui-tab :title="`Completed (${completedNotificationsTotalCount})`">
                    <notifications-tab
                        :notifications="completedNotifications"
                        :totalPages="Math.ceil(completedNotificationsTotalCount / defaultPageSize)"
                        :shouldBeDisabled="true"
                        :currentPage="currentPage"
                        @onPageChanged="handlePageChanged($event, tabTypes.Completed)"
                    />
                </pui-tab>
            </pui-tabs>
        </div>
        <div v-if="showClearNotisToast" class="pui-toast-message toast-message">
            <div class="pui-toast-message__title">
                {{ $t('clearNotificationToastTitle') }}
            </div>
            <div class="pui-toast-message__copy">
                {{ $t('clearNotificationToastMessage') }}
            </div>
            <div class="pui-toast-message__actions">
                <button type="button" class="pui-toast-message__action" @click="setShowClearNotisToast(false)">
                    {{ $t('clearNotificationToastNoAction') }}
                </button>
                <button type="button" class="pui-toast-message__action" @click="clearAllNotifications">
                    {{ $t('clearNotificationToastYesAction') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import { Notification, UseCase } from '@/models';
import { NotificationService, UseCaseService } from '@/services';
import { EventBus } from '@/utils';
import NotificationsTab from '@/components/notifications/notifications-overview-page/notifications-tab.vue';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';
import MessageHandler from '@/utils/message-handler';

enum TabsEnum {
    Tasks,
    Notifications,
    Completed,
}

const DEFAULT_PAGE_SIZE = 20;

@Component({
    name: 'notifications-overview-page',
    components: {
        notificationsTab: NotificationsTab,
    },
})
export default class NotificationsOverviewPage extends Vue {
    private notificationService: NotificationService = new NotificationService();
    private useCaseService: UseCaseService = new UseCaseService();
    private selectedAppFilters: any[] = [];
    private selectedTab = this.getSelectedTabFromQueryParam();
    private notificationsLoaded = false;
    private showClearNotisToast = false;
    private currentPage = 1;
    private defaultPageSize = DEFAULT_PAGE_SIZE;
    private tabTypes = TabsEnum;

    private actionItemsTaskNotis: any[] = [
        {
            name: 'markAllAsRead',
            displayName: this.$t('markAsReadAction'),
            iconName: 'check',
            isPrimary: false,
            enabled: true,
        },
    ];

    private actionItemsDisplayNotis: any[] = [
        {
            name: 'clearAllNotifications',
            displayName: this.$t('notificationHeaderContextMenu.clear'),
            iconName: 'delete',
            isPrimary: false,
            enabled: true,
        },
    ];

    private async mounted() {
        await this.getAllNotifications();
        if (!this.$store.getters.useCases || this.$store.getters.useCases.length === 0) {
            const useCases = (await this.useCaseService.getAllUseCases()).result.items;
            this.$store.commit('useCases', useCases);
        }
    }

    private async getAllNotifications() {
        this.$store.commit('notifications/showTabs', false);
        await Promise.all([this.getNotifications(TabsEnum.Tasks, 1),
            this.getNotifications(TabsEnum.Notifications, 1),
            this.getNotifications(TabsEnum.Completed, 1)]);
        this.$store.commit('notifications/showTabs', true);
    }

    private get tasks(): any[] {
        return this.$store.getters['notifications/tasks'];
    }

    private get tasksTotalCount(): number {
        return this.$store.getters['notifications/tasksTotalCount'];
    }

    private get simpleNotifications(): any[] {
        return this.$store.getters['notifications/simpleNotifications'];
    }

    private get simpleNotificationsTotalCount(): number {
        return this.$store.getters['notifications/simpleNotificationsTotalCount'];
    }

    private get completedNotifications(): any[] {
        return this.$store.getters['notifications/completedNotifications'];
    }

    private get completedNotificationsTotalCount(): number {
        return this.$store.getters['notifications/completedNotificationsTotalCount'];
    }

    private get allUseCases(): any[] {
        return this.$store.getters.useCases.map((u: UseCase) => ({ displayName: u.name, value: u.id }));
    }

    private get showTabs(): boolean {
        return this.$store.getters['notifications/showTabs'];
    }

    private async getNotifications(tab: TabsEnum, pageNumber: number) {
        this.notificationsLoaded = false;

        // tslint:disable:prefer-const
        switch (tab) {
            case TabsEnum.Tasks: {
                let { items, totalCount } = await this.getSpecificNotifications(pageNumber, false, false, true);
                if (this.selectedAppFilters.length > 0) {
                    items = items.filter((n: Notification) => this.selectedAppFilters.includes(n.useCaseId));
                }
                this.$store.commit('notifications/setTasks', items);
                this.$store.commit('notifications/setTasksTotalCount', totalCount);
                break;
            }

            case TabsEnum.Notifications: {
                let { items, totalCount } = await this.getSpecificNotifications(pageNumber, false, true, false);
                if (this.selectedAppFilters.length > 0) {
                    items = items.filter((n: Notification) => this.selectedAppFilters.includes(n.useCaseId));
                }
                this.$store.commit('notifications/setSimpleNotifications', items);
                this.$store.commit('notifications/setSimpleNotificationsTotalCount', totalCount);
                break;
            }

            case TabsEnum.Completed: {
                let { items, totalCount } = await this.getSpecificNotifications(pageNumber, true, true, true);
                if (this.selectedAppFilters.length > 0) {
                    items = items.filter((n: Notification) => this.selectedAppFilters.includes(n.useCaseId));
                }
                this.$store.commit('notifications/setCompletedNotifications', items);
                this.$store.commit('notifications/setCompletedNotificationsTotalCount', totalCount);
                break;
            }
        }
        // tslint:enable:prefer-const

        this.notificationsLoaded = true;
    }

    private async getSpecificNotifications(
      pageNumber: number,
      onlyCompleted: boolean,
      listNotifications: boolean,
      listTasks: boolean,
      relevantUseCaseIds: number[] = this.selectedAppFilters
    ) {
        try {
            this.$store.commit('loading', true);

            const requestParamsCompleted = {
                page: pageNumber,
                size: DEFAULT_PAGE_SIZE,
                term: '',
            };

            const requestParamsOther = {
                ListNotifications: listNotifications,
                ListTasks: listTasks,
                GetRead: true,
                RelevantUseCaseIds: relevantUseCaseIds,
                ...requestParamsCompleted,
            };

            const notificationsResult = onlyCompleted ?
                (await this.notificationService.getAllCompletedNotifications(requestParamsCompleted)).result :
                (await this.notificationService.getAllNotifications(requestParamsOther)).result;

            const totalPages = notificationsResult.totalPages;
            let items = notificationsResult.items;

            if (this.selectedAppFilters.length > 0) {
                items = items.filter((n: Notification) => this.selectedAppFilters.includes(n.useCaseId));
            }

            return {
                items,
                totalPages,
                totalCount: notificationsResult.totalCount,
            };

        } catch (err) {
            MessageHandler.showToastMessage(
              this.$t('notificationsNotLoaded') as string,
              ToastMessageTypes.ERROR
            );
            throw err;
        } finally {
            this.$store.commit('loading', false);
        }
    }

    private getMultiselectFilters(): object[] {
        return [{
            name: 'appMultiFilter',
            displayName: this.$t('application'),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            config: {
                options: this.allUseCases,
                hasSearchInput: true,
                searchInputPlaceholder: this.$t('application'),
            },
            selectedValues: {
                options: this.selectedAppFilters,
            },
            appliedValues: {
                options: this.selectedAppFilters,
            },
        }];
    }

    private generateSelectInputObjects(applicableValues: any): any {
        if (applicableValues) {
            return applicableValues.map((v: any) => ({ label: v.name, value: v.id }));
        }
        return [];
    }

    private filterValueChanged(e: Event): void {
        this.selectedAppFilters = Object(e)['appMultiFilter'];
        this.getAllNotifications();
    }

    private markAllNotificationsAsRead(): void {
        this.tasks.forEach((n: Notification) => {
            this.$store.dispatch('notifications/markNotificationAsRead', n);
        });
        this.notificationService.markNotificationsAsRead(this.tasks.map((n) => n.id));
    }

    private getSelectedTabFromQueryParam(): number {
      const { tab } = this.$route.query;
      return tab === '0' ? TabsEnum.Tasks : TabsEnum.Notifications;
    }

    private onTabChanged(idOfNewTab: number): void {
        this.selectedTab = idOfNewTab;
        this.currentPage = 1;
        this.$router.replace({name: 'NotificationsOverviewPage', query: {tab: idOfNewTab.toString()}});
        this.handlePageChanged(this.currentPage, idOfNewTab);
    }

    private async clearAllNotifications(): Promise<void> {
        const notificationsToBeCleared = this.simpleNotifications.map((n: Notification) => n.id);
        this.setShowClearNotisToast(false);
        await this.notificationService.clearNotificationsByList(notificationsToBeCleared);
        this.getNotifications(TabsEnum.Notifications, 1);
        EventBus.$emit(EventBus.NOTIFICATIONS.RELOAD_HEADER_NOTIFICATIONS);
    }

    private setShowClearNotisToast(val: boolean): void {
        this.showClearNotisToast = val;
    }

    private handlePageChanged(pageNumber: number, tab: TabsEnum): void {
        this.getNotifications(tab, pageNumber);
        this.currentPage = pageNumber;
    }

    private created() {
        EventBus.$on(EventBus.NOTIFICATIONS.RELOAD_SIMPLE_NOTIFICATIONS, () => {
            this.getNotifications(TabsEnum.Notifications, 1);
        });
    }
}
</script>

<style lang='less'>
@import '../../../variables.less';

.notifications-overview {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 8rem);
    font-family: Roboto, sans-serif;

    .toast-message {
        max-width: fit-content;
        position: absolute;
        bottom: 1em;
        right: 1em;
        .pui-toast-message__title {
            margin-bottom: 2rem;
        }
    }

    &__container {
        margin: 0 8%;
    }

    &__title {
        margin-bottom: 2.8rem;
    }

    &__filter {
        margin: 2.8rem 0;
        .pui-form-input-field {
          input {
            padding: 0;
          }
        }
    }
}
</style>
