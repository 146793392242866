import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import MainPage from '@/components/main-page/main-page.vue';
import ContactPage from '@/components/contact-page/contact-page.vue';
import DigitalTransformationPage from '@/components/digital-transformation-page/digital-transformation-page.vue';
import NotificationsOverviewPage from '@/components/notifications/notifications-overview-page/notifications-overview-page.vue';
import IncidentPage from '@/components/incident-page/incident-page.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/search',
        name: 'Search',
        component: MainPage,
        meta: {
            title: 'Search results',
        },
    },
    {
        path: '/digital-transformation',
        name: 'DigitalTransformation',
        component: DigitalTransformationPage,
        meta: {
            title: 'Digital Transformation',
        },
    },
    {
        path: '/contact-page/:useCaseId?',
        name: 'ContactPage',
        component: ContactPage,
        meta: {
            title: 'Contact',
        },
        props: true,
    },
    {
        path: '/incident-page/:useCaseId?',
        name: 'IncidentPage',
        component: IncidentPage,
        meta: {
            title: 'Report an Incident',
        },
        props: true,
    },
    {
        path: '/notifications-overview',
        name: 'NotificationsOverviewPage',
        component: NotificationsOverviewPage,
        meta: {
            title: 'Actions & Overview',
        },
    },
    {
        path: '/:appId?',
        name: 'MainPage',
        component: MainPage,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});


export default router;
