<template>
  <div v-if="useCases.length" class="preview-card-container">
    <div class="flex-row">
      <span class="category-name">{{ categoryName }}</span>
    </div>
    <div v-pui-form-grid-row :class="['grid-row'].join(' ')">
      <div
        v-pui-form-grid-column="{s:12, m:6, l:4, xl:3, xxl: 3}"
        class="flex-col categories-container"
        v-for="(useCase, useCaseIndex) of useCases"
        :key="useCaseIndex"
        @click="redirectTo(useCase)"
      >
        <app-card :useCase="useCase"/>
      </div>
    </div>
    <hr v-if="hasDivider">
  </div>
</template>

<script lang="ts">

import { UseCase } from '@/models';
import { Component, Vue, Prop } from 'vue-property-decorator';
import AppCard from '@/components/app-card/app-card.vue';

@Component({
    name: 'category-card',
    components: {
      appCard: AppCard,
    },
})
export default class CategoryCard extends Vue {
    @Prop({required: true, default: '' })
    private categoryName!: string;

    @Prop({required: true, default: [] })
    private useCases!: UseCase[];

    @Prop({required: false, default: true })
    private hasDivider!: boolean;

    private redirectTo(useCase: UseCase) {
        switch ( useCase.url ) {
            case 'digital-transformation':
                this.$router.push({ path: `/` + useCase.url });
                break;
            case 'underConstruction':
                alert('Under construction');
                break;
            default:
                this.$emit('trigger-open-details-modal', useCase);
        }
    }
}
</script>

<style lang="less" scoped>
@import '../../variables.less';

.preview-card-container {
  width: 100%;
  padding: 0 4.2rem 0 4.2rem;
  margin-bottom: 2.4rem;

  .grid-row {
    padding: 0 !important;
    margin-top: 1.5rem;
  }

  hr {
    border-bottom: 1px;
    opacity: 0.5;
    color: @separator-grey;
    width: 100%;

    margin: 4rem 0 4rem;
  }

  .category-name {
    font-weight: bold;
    font-size: 2rem;
  }

  .categories-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
</style>
