export enum StatusEnum {
    None,
    New,
    Updated,
    Soon,
    Live,
    NotShownInUi,
}

export interface Statuses {
    [index: string]: StatusEnum;
}

export const DisplayedStatusEnumFilters: StatusEnum[] = [
    StatusEnum.New,
    StatusEnum.Updated,
    StatusEnum.Soon,
    StatusEnum.Live,
];
