export class IdeaSuggestionTableItem {
    private id?: number;
    private coodeId: string;
    private description: string;
    private title: string;
    private productOwner: string;
    private businessOwner: string;

    public constructor(data?: any) {
        this.id = data?.id;
        this.coodeId = data?.coodeId;
        this.description = data?.description;
        this.title = data?.title;
        this.businessOwner = data?.businessOwner;
        this.productOwner = data?.productOwner;
    }
}
