import Vue from 'vue';
import { Notification } from '@/models';
import { mapNotification as mapHeaderNotification } from '@/utils/mappers/notificationMapper';

export const notifications = {
    namespaced: true,
    state: () => ({
        showNotifications: true,
        showTabs: true,
        headerNotificationsMapped: Array<any>(),
        tasks: Array<Notification>(),
        tasksTotalCount: 0,
        simpleNotifications: Array<Notification>(),
        simpleNotificationsTotalCount: 0,
        completedNotifications: Array<Notification>(),
        completedNotificationsTotalCount: 0,
    }),
    getters: {
        showNotifications: (state: any): boolean => state.showNotifications,
        showTabs: (state: any): boolean => state.showTabs,
        headerNotificationsMapped: (state: any): any[] => state.headerNotificationsMapped,
        tasks: (state: any): Notification[] => state.tasks,
        tasksTotalCount: (state: any): number => state.tasksTotalCount,
        simpleNotifications: (state: any): number => state.simpleNotifications,
        simpleNotificationsTotalCount: (state: any): number => state.simpleNotificationsTotalCount,
        completedNotifications: (state: any): Notification[] => state.completedNotifications,
        completedNotificationsTotalCount: (state: any): number => state.completedNotificationsTotalCount,
    },
    mutations: {
        setHeaderNotificationsMapped: (state: any, headerNotificationsMapped: any[]): void => {
            state.headerNotificationsMapped = [...headerNotificationsMapped];
        },
        addNotificationToHeader: (state: any, { notification, useCases }: any): void => {
            const mappedNotification = mapHeaderNotification(notification, useCases);
            state.headerNotificationsMapped = [mappedNotification, ...state.headerNotificationsMapped];
        },
        addTask: (state: any, notification: Notification): void => {
            state.tasks.pop();
            state.tasks = [notification, ...state.tasks];
            state.tasksTotalCount += 1;
        },
        addSimpleNotification: (state: any, notification: Notification): void => {
            state.simpleNotifications.pop();
            state.simpleNotifications = [notification, ...state.simpleNotifications];
            state.simpleNotificationsTotalCount += 1;
        },
        addCompletedNotification: (state: any, notification: Notification): void => {
            state.completedNotifications.pop();
            state.completedNotifications = [notification, ...state.completedNotifications];
            state.completedNotificationsTotalCount += 1;
        },
        removeHeaderNotification: (state: any, id: number): void => {
            const index = state.headerNotificationsMapped.findIndex((n: any) => n.id === id);
            if (index === -1) {
                return;
            }
            state.headerNotificationsMapped.splice(index, 1);
            state.headerNotificationsMapped = [...state.headerNotificationsMapped];
        },
        removeTask: (state: any, id: number): void => {
            const index = state.tasks.findIndex((n: any) => n.id === id);
            if (index === -1) {
                return;
            }
            state.tasks.splice(index, 1);
            state.tasks = [...state.tasks];
            state.tasksTotalCount -= 1;
        },
        removeSimpleNotification: (state: any, id: number): void => {
            const index = state.simpleNotifications.findIndex((n: any) => n.id === id);
            if (index === -1) {
                return;
            }

            state.simpleNotifications.splice(index, 1);
            state.simpleNotificationsTotalCount -= 1;
        },
        showNotifications: (state: any, shouldShow: boolean): void => {
            state.showNotifications = shouldShow;
        },
        showTabs: (state: any, shouldShow: boolean): void => {
            state.showTabs = shouldShow;
        },
        markNotificationAsRead: (state: any, notification: Notification): void => {
            const idx = state.tasks.findIndex((n: Notification) => n.id === notification.id);

            if (idx !== -1) {
                notification.isRead = true;
                Vue.set(state.tasks, idx, notification);
            }
        },
        setTasks: (state: any, tasks: Notification[]): void => {
            state.tasks = [...tasks];
        },
        setTasksTotalCount: (state: any, count: number): void => {
            state.tasksTotalCount = count;
        },
        setSimpleNotifications: (state: any, simpleNotifications: Notification[]): void => {
            state.simpleNotifications = [...simpleNotifications];
        },
        setSimpleNotificationsTotalCount: (state: any, count: number): void => {
            state.simpleNotificationsTotalCount = count;
        },
        setCompletedNotifications: (state: any, completedNotifications: Notification[]): void => {
            state.completedNotifications = [...completedNotifications];
        },
        setCompletedNotificationsTotalCount: (state: any, count: number): void => {
            state.completedNotificationsTotalCount = count;
        },
    },
    actions: {
        markNotificationAsRead: ({ commit }: any, notification: Notification): void => {
            commit('showNotifications', false);
            commit('showTabs', false);
            commit('markNotificationAsRead', notification);
            commit('removeHeaderNotification', notification.id);
            setTimeout(() => commit('showNotifications', true), 0);
            setTimeout(() => commit('showTabs', true), 0);
        },
        addNotification: ({ commit, rootGetters }: any, notification: any): void => {
            commit('showNotifications', false);
            commit('showTabs', false);
            notification.actions ? commit('addTask', notification) : commit('addSimpleNotification', notification);
            commit('addNotificationToHeader', { notification, useCases: rootGetters.useCases });
            setTimeout(() => commit('showNotifications', true), 0);
            setTimeout(() => commit('showTabs', true), 0);
        },
        removeTask: ({ commit }: any, notification: Notification): void => {
            commit('showNotifications', false);
            commit('showTabs', false);
            commit('removeTask', notification.id);
            commit('removeSimpleNotification', notification.id);
            commit('removeHeaderNotification', notification.id);
            commit('addCompletedNotification', notification);
            setTimeout(() => commit('showNotifications', true), 0);
            setTimeout(() => commit('showTabs', true), 0);
        },
        removeSimpleNotification: ({ commit }: any, notification: Notification): void => {
            commit('showNotifications', false);
            commit('showTabs', false);
            commit('removeSimpleNotification', notification.id);
            commit('removeHeaderNotification', notification.id);
            setTimeout(() => commit('showNotifications', true), 0);
            setTimeout(() => commit('showTabs', true), 0);
        },
    },
};
