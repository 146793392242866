import { AccessApplicationForm, ApiResponse, ApplicationForm } from '@/models';
import { ApplicationIncidentForm, ApplicationIncidentResponse } from '@/models/form';
import SdkMetaDataApiHelper from '@/utils/fe-sdk/sdk-meta-data-api-helper';

export class ModuleApplicationService {
    private api = new SdkMetaDataApiHelper('use-cases');

    public async submitApplication(
        values: ApplicationForm,
        attachment?: File,
    ): Promise<ApiResponse<ApplicationForm[]>> {
        const formData = new FormData();

        Object.keys(values).forEach((key) => {
            formData.set(key, values[key as keyof ApplicationForm].toString());
        });

        if (attachment) {
            formData.set('Attachment', attachment);
        }

        return (await this.api.post(formData, 'feedback')).data as Promise<ApiResponse<ApplicationForm[]>>;
    }

    public async submitAccessAppRequest(values: AccessApplicationForm): Promise<ApiResponse<ApplicationForm[]>> {
        return (await this.api.post(values, 'application')).data as Promise<ApiResponse<ApplicationForm[]>>;
    }

    public async createIncident(values: ApplicationIncidentForm): Promise<ApiResponse<ApplicationIncidentResponse>> {
        return (await this.api.post(values, 'incident')).data as Promise<ApiResponse<ApplicationIncidentResponse>>;
    }

    public async uploadAttachment(incidentId: string, file: File): Promise<ApiResponse<ApplicationIncidentResponse>> {
        const formData = new FormData();
        formData.set('IncidentSysId', incidentId);
        formData.set('File', file);

        return (await this.api.post(formData, 'incident/upload')).data as Promise<ApiResponse<ApplicationIncidentResponse>>;
    }
}
