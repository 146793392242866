var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.searchIdeas.length)?_c('div',{staticClass:"search-result-box mt-2"},[_c('pui-table',{attrs:{"vendor-options":_vm.tableConfig,"withPagination":true},scopedSlots:_vm._u([{key:_vm.tableColumnHeaders.coodeId,fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.coodeId)+" ")]}},{key:_vm.tableColumnHeaders.title,fn:function(ref){
var row = ref.row;
return [_c('pui-link',{attrs:{"href":_vm.getUrl(row.id),"title":row.title,"target":"_blank"}})]}},{key:_vm.tableColumnHeaders.description,fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getShortenedDescription(row.description))+" ")]}},{key:_vm.tableColumnHeaders.businessOwner,fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.businessOwner)+" ")]}},{key:_vm.tableColumnHeaders.productOwner,fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.productOwner)+" ")]}}],null,true)})],1):_vm._e(),(!_vm.searchIdeas.length && !_vm.$store.state.isLoading)?_c('pui-loader-error',{staticClass:"mt-2",attrs:{"title":_vm.$t('noSearchResultsTitle'),"message":_vm.$t('noSearchResultsMessage'),"icon":"error-empty-data"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }