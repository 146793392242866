import { ApiResponse, IdeasSuggestion, ApiResponseIdeas } from '@/models';
import SdkMetaDataApiHelper from '@/utils/fe-sdk/sdk-meta-data-api-helper';

export class IdeasService {
    private api = new SdkMetaDataApiHelper('ideas');

    public async getIdeasSuggestions(search?: string): Promise<ApiResponse<ApiResponseIdeas<IdeasSuggestion>>> {
        return (await this.api.getByPathOrParam(`suggestions?Term=${search}`)).data as Promise<ApiResponse<ApiResponseIdeas<IdeasSuggestion>>>;
    }

    public async getAllIdeas(search?: string): Promise<ApiResponse<ApiResponseIdeas<IdeasSuggestion>>> {
        return (await this.api.getByPathOrParam(`list?Term=${search}`))?.data as Promise<ApiResponse<ApiResponseIdeas<IdeasSuggestion>>>;
    }
}
