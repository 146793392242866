<template>
  <editor-content class="editor" :editor="editor" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';

@Component({
    components: {
        EditorContent,
    },
})
export default class RichTextEditor extends Vue {
  private editor: any = null;

  @Prop({ required: true })
  private value!: any;

  private mounted(): void {
    this.editor = new Editor({
      content: this.value,
      editable: false,
      extensions: [
        StarterKit,
      ],
    });
  }

    private beforeDestroy(): void {
      this.editor.destroy();
  }
}
</script>


<style lang="less">
@import '~@/variables.less';

.editor {
  display: flex;
  flex-direction: column;
  max-height: 26rem;
  color: #0d0d0d;
  background-color: #fff;

  p {
        strong {
            color: black;
            font-weight: bold;
        }
        margin: 0;
    }

    ul {
      margin: 0;
      li {
          list-style-type: unset;
          margin-left: 3rem;
          line-height: 1.5rem;
          border-bottom: none;
      }
    }

    .ProseMirror {
        font-family: 'Roboto', 'Noto Sans', Arial, -apple-system, BlinkMacSystemFont, sans-serif;
    }
}
</style>
