import sdk from './sdk';

export default class SdkMetaDataApiHelper {
    private endpoint?: string;

    constructor(endpoint?: string) {
        this.endpoint = endpoint;
    }

    public async get(query?: string, additionalPath?: string) {
        let endpoint = this.mergeEndpoint(additionalPath);
        endpoint = query ? `${endpoint}?${query}` : endpoint;
        return await sdk?.core.metaData.request.api.get(endpoint);
    }

    public async getByPathOrParam(pathParam: string | number) {
        const endpoint = this.mergeEndpoint(pathParam);
        return await sdk?.core.metaData.request.api.get(endpoint);
    }

    public async post(data?: object, additionalPath?: string) {
        const endpoint = this.mergeEndpoint(additionalPath);
        return await sdk?.core.metaData.request.api.post(endpoint, data);
    }

    public async put(pathOrParam?: string | number, data?: object) {
        const endpoint = this.mergeEndpoint(pathOrParam);
        return await sdk?.core.metaData.request.api.put(endpoint, data);
    }

    public async delete(pathOrParam: string | number) {
        const endpoint = this.mergeEndpoint(pathOrParam);
        return await sdk?.core.metaData.request.api.delete(endpoint);
    }

    private mergeEndpoint(additionalPath?: string | number): string {
        if (!this.endpoint) {
            return additionalPath
                ? `${additionalPath}`
                : '';
        }

        return additionalPath
            ? `${this.endpoint}/${additionalPath}`
            : this.endpoint;
    }
}
