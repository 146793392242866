import { ApiResponse, ApiResponseList, Application } from '@/models';
import SdkMetaDataApiHelper from '@/utils/fe-sdk/sdk-meta-data-api-helper';

export class AppService {
    private api = new SdkMetaDataApiHelper('use-cases');

    public async getApps(): Promise<ApiResponse<ApiResponseList<Application>>> {
        return (await this.api.get()).data as Promise<ApiResponse<ApiResponseList<Application>>>;
    }
}
