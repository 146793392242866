<template>
    <div class="app-container">
        <div class="flex-col wrapper-modules mb-1">
            <div class="filter-row mb-1">
                <div class="wrapper-search">
                    <header-search
                        @type-to-search="searchSuggestions"
                        @search-item="searchModules"
                        @goto-result="gotoResult"
                    />
                </div>
            </div>
             <div :class="['filter-row mb-1', filtersActive ? 'mt-4' : 'mt-3']">
                <div class="wrapper-filter">
                  <pui-tooltip/>
                  <pui-filter
                      v-if="isUsecaseLoaded"
                      :use-router="false"
                      @changed:applied-values="filterValueChanged"
                      :config="{
                        filters: getMultiselectFilters()
                      }"
                      vuex-namespace="ns1"
                  />
                </div>
            </div>

        </div>
        <pui-tabs class="search-result mt-1" v-if="showSearchResult" :key="resultsKey">
            <pui-tab v-for="tab in tabs" :key="tab.name" :title="tab.title">
                <search-result v-if="tab.name === $t('apps')" :search-items="searchResults" @open-details-modal="toggleModal" />
                <search-result v-if="tab.name === $t('reports')" :search-items="searchReports" @open-details-modal="toggleModal" />
                <ideas-suggestion v-if="tab.name === $t('ideas')" :search-ideas="searchIdeasSuggestions" />
            </pui-tab>
        </pui-tabs>
        <br />
        <app-grid
            v-if="!showSearchResult"
            :filtered-use-cases="allUseCases"
            :filtersApplied="filtersActive"
            @open-details-modal="toggleModal"
        />
        <app-details-modal
            v-if="showModal"
            :selected-use-case="selectedUseCase"
            @cancel="toggleModal()"/>
    </div>
</template>

<script lang='ts'>
import { Component, Vue, Watch } from 'vue-property-decorator';

import AppGrid from '@/components/main-page/app/app-grid.vue';

import {
  UseCase,
  IdeasSuggestion,
  SearchResult as SuggestionResult,
  Category,
  Technology,
  StatusEnum,
} from '@/models';

import { UseCaseService, IdeasService, CategoryService, TechnologyService } from '@/services';

import HeaderSearch from '@/components/header-search/header-search.vue';
import SearchResult from '@/components/main-page/search-result/search-result.vue';
import AppDetailsModal from '@/components/app-details-modal/app-details-modal.vue';
import FadeTransition from '@/components/transitions/fade-transition/fade-transition.vue';
import IdeasSuggestions from '@/components/main-page/ideas-suggestion/ideas-suggestions.vue';

import { Tabs } from '@/utils/search-tabs';
import { ApplicationType } from '@/models/usecase';
import { ApplicationTypeList } from '@/utils/application-types';
import { DisplayedStatusEnumFilters } from '@/models/status-enum';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';
import MessageHandler from '@/utils/message-handler';

const FilteringKeys = {
    Category: 'category',
    Status: 'status',
    Technology: 'technology',
    ApplicationType: 'applicationType',
};

@Component({
    name: 'main-page',
    components: {
        appGrid: AppGrid,
        headerSearch: HeaderSearch,
        appDetailsModal: AppDetailsModal,
        fadeTransition: FadeTransition,
        searchResult: SearchResult,
        ideasSuggestion: IdeasSuggestions,
    },
})
export default class MainPage extends Vue {

    private useCaseService: UseCaseService = new UseCaseService();
    private ideasService: IdeasService = new IdeasService();
    private categoryService: CategoryService = new CategoryService();
    private technologyService: TechnologyService = new TechnologyService();

    private useCases: UseCase[] = [];
    private searchTerm = '';

    private showModal = false;
    private selectedUseCase: UseCase | null = null;
    private itemsPerPage = [5, 10, 15, 20, 25, 30, 50];
    private showSearchResult = false;
    private searchResults: UseCase[] = [];
    private searchReports: UseCase[] = [];
    private searchIdeasSuggestions: IdeasSuggestion[] = [];
    private tabs = Tabs;
    private resultsKey = 0;

    private filterByStatuses: number[] = [];
    private filterByCategory: number[] = [];
    private filterByTechnology: number[] = [];
    private filterByApplicationType: number[] = [];

    private isUsecaseLoaded = false;
    private filtersActive = false;

    @Watch('$route.query.q')
    private onSearchQuery(q: string) {
        const searchFlag = Boolean(q);
        this.showSearchResult = searchFlag;

        if (!searchFlag) {
            this.searchModules('', false);
        }
    }

    private async mounted() {
        await this.getUseCases();
        await this.getIdeasSuggestions();
        await this.getLengthOfSearchTabs();
        await this.getCategories();
        await this.getTechnologies();

        if (this.$route.query.appId) {
          const useCase = this.$store.getters.useCases
                        .find((currentUseCase: UseCase) => currentUseCase.id.toString() === this.$route.query.appId);
          this.toggleModal(useCase);
        }
        this.filtersActive = false;
        this.$root.$on('clearSearchFilters', async () => {
          this.filterByCategory = [];
          this.filterByTechnology = [];
          this.filterByStatuses = [];
          this.filterByApplicationType = [];
        });
    }

    get allUseCases(): UseCase[] {
        return this.$store.getters.useCases;
    }

    private async getUseCases() {
        this.$store.commit('loading', true);
        try {
            let useCases = (await this.useCaseService.getAllUseCases(this.searchTerm,
                this.filterByCategory, this.filterByTechnology, this.filterByStatuses, this.filterByApplicationType))
                .result.items;
            let reports: UseCase[] = [];
            if (this.showSearchResult) {
              this.searchResults = useCases.map((useCase: UseCase): UseCase => {
                useCase.logo = this.getLogo(useCase.logo);
                return useCase;
              }).filter((u: UseCase) => u.id !== 3 && u.id !== 4);
              reports = useCases.filter((useCase: UseCase) => {
                return useCase.applicationType === ApplicationType[ApplicationType.ReportApplication];
              });
              useCases = useCases.filter((useCase: UseCase) => {
                return useCase.applicationType !== ApplicationType[ApplicationType.ReportApplication];
              });
            } else {
              useCases = useCases.map((useCase: UseCase): UseCase => {
                useCase.logo = this.getLogo(useCase.logo);
                return useCase;
              }).filter((u: UseCase) => u.id !== 3 && u.id !== 4);
            }
            this.$store.commit('useCases', useCases);
            this.$store.commit('reports', reports);
            this.isUsecaseLoaded = true;
        } catch (err) {
            MessageHandler.showToastMessage(
              this.$t('errorLoadingUseCases') as string,
              ToastMessageTypes.ERROR
            );
            throw err;
        } finally {
            this.$store.commit('loading', false);
        }
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch (e) {
            return 'app-generic';
        }
    }

    private gotoResult(item: SuggestionResult) {
        let useCaseResult = null;
        let ideaUrl = null;

        if (typeof item.coodeId !== 'undefined') {
            ideaUrl = `${process.env.VUE_APP_DIGITAL_IDEA_TRACKER_UI_BASE_URL}#/ideas/${item.id}`;
            window.open(ideaUrl, '_blank')?.focus();
        } else {
            useCaseResult = this.$store.getters.useCases
                .find((useCase: UseCase) => useCase.id === item.id);
            if (useCaseResult) {
                this.toggleModal(useCaseResult);
            }
        }
    }

    private searchSuggestions(searchTerm: string): void {
        let useCasesSuggestions: UseCase[] = [];
        let ideasSuggestions: IdeasSuggestion[]|undefined = [];
        this.searchTerm = searchTerm;
        // this.$store.commit('clearSearchSuggestions');

        this.$store.commit('loadingSuggestions', true);
        this.useCaseService.getUseCasesSuggestions(this.searchTerm)
            .then((r) => {
                useCasesSuggestions = r.result.items;
                this.$store.commit('loadingSuggestions', false);
        });

        this.$store.commit('loadingSuggestions', true);
        this.ideasService.getIdeasSuggestions(this.searchTerm)
            .then((r) => {
                ideasSuggestions = r.result.ideas;
                this.$store.commit('loadingSuggestions', false);

                //
                this.$store.commit('searchUseCasesSuggestions', useCasesSuggestions);
                this.$store.commit('searchIdeasSuggestions', ideasSuggestions);
            });
    }

    private async searchModules(searchItem: string, showSearchResult: boolean): Promise<void> {
        this.searchTerm = searchItem;
        this.showSearchResult = typeof showSearchResult !== 'undefined'
            ? showSearchResult
            : typeof this.$route.query.q !== 'undefined';
        await this.getUseCases();
        this.searchResults = this.$store.getters.useCases;
        this.searchReports = this.$store.getters.reports;
        await this.getIdeasSuggestions();
        this.searchIdeasSuggestions = this.$store.getters.ideas;
        await this.getLengthOfSearchTabs();
        this.tabs = this.$store.getters.tabs;
        this.resultsKey++;
    }

    private toggleModal(useCase?: UseCase) {
        this.showModal = !this.showModal;
        if (useCase) {
            this.selectedUseCase = useCase;
        }
    }

    private async getIdeasSuggestions(): Promise<void> {
        this.$store.commit('loading', true);
        try {
            const ideas = (await this.ideasService.getAllIdeas(this.searchTerm))
                .result.items;
            this.$store.commit('ideas', ideas);
        } catch (err) {
            MessageHandler.showToastMessage(
              this.$t('errorLoadingIdeasSuggestions') as string,
              ToastMessageTypes.ERROR
            );
            throw err;
        } finally {
            this.$store.commit('loading', false);
        }
    }

    private getLengthOfSearchTabs() {
        const resultsLength: number[] = [
          this.$store.getters.useCases.length,
          this.$store.getters.ideas.length,
          this.$store.getters.reports.length,
        ];
        const tabs = Tabs.map((t, i) => {
            return {name: t.name, title: `${t.title} (${resultsLength[i]})` };
        });
        this.$store.commit('tabs', tabs);
    }

    private getStatusFilters(): object[] {
        return DisplayedStatusEnumFilters.map((status) => ({
          displayName: this.$t(`appStatuses.${StatusEnum[status].toLowerCase()}`),
          value: status,
        }));
    }

    private getApplicationTypeFilters(): object[] {
        return ApplicationTypeList.map((applicationType) => ({
            displayName: this.$t(`appTypes.${ApplicationType[applicationType].toLowerCase()}`),
            value: applicationType,
        }));
    }

    private async getCategories(): Promise<void> {
        this.$store.commit('loading', true);
        try {
            const categories = (await this.categoryService.getCategories()).result.items;
            this.$store.commit('categories', categories);
        } catch (err) {
            MessageHandler.showToastMessage(
              this.$t('errorLoadingCategories') as string,
              ToastMessageTypes.ERROR
            );
            throw err;
        } finally {
            this.$store.commit('loading', false);
        }
    }

    private async getTechnologies(): Promise<void> {
        this.$store.commit('loading', true);
        try {
            const technologies = (await this.technologyService.getTechnologies()).result.items;
            this.$store.commit('technologies', technologies);
        } catch (err) {
            MessageHandler.showToastMessage(
              this.$t('errorLoadingTechnologies') as string,
              ToastMessageTypes.ERROR
            );
            throw err;
        } finally {
            this.$store.commit('loading', false);
        }
    }

    private getCategoryFilters(): object[] {
        return this.$store.getters.categories.map((category: Category) => ({
            displayName: category.name,
            value: category.id,
        }));
    }

    private getTechnologyFilters(): object[] {
        return this.$store.getters.technologies.map((technology: Technology) => ({
            displayName: technology.name,
            value: technology.id,
        }));
    }

    private getMultiselectFilters(): object[] {
        return [{
            name: FilteringKeys.Status,
            displayName: this.$t('byStatus'),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            hasSearchInput: false,
            searchInputPlaceholder: this.$t('status'),
            config: {
                options: this.getStatusFilters(),
            },
            selectedValues: {
                options: this.filterByStatuses,
            },
            appliedValues: {
                options: this.filterByStatuses,
            },
        },

        {
            name: FilteringKeys.Category,
            displayName: this.$t('byCategory'),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            hasSearchInput: false,
            searchInputPlaceholder: this.$t('category'),
            config: {
                options: this.getCategoryFilters(),
            },
            selectedValues: {
                options: this.filterByCategory,
            },
            appliedValues: {
                options: this.filterByCategory,
            },
        },

        {
            name: FilteringKeys.Technology,
            displayName: this.$t('byTechnology'),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            hasSearchInput: false,
            searchInputPlaceholder: this.$t('technology'),
            config: {
                options: this.getTechnologyFilters(),
            },
            selectedValues: {
                options: this.filterByTechnology,
            },
            appliedValues: {
                options: this.filterByTechnology,
            },
        },

        {
            name: FilteringKeys.ApplicationType,
            displayName: this.$t('byApplicationType'),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            hasSearchInput: false,
            searchInputPlaceholder: this.$t('applicationType'),
            config: {
                options: this.getApplicationTypeFilters(),
            },
            selectedValues: {
                options: this.filterByApplicationType,
            },
            appliedValues: {
                options: this.filterByApplicationType,
            },
        },
        ];
    }

    private filterValueChanged(e: Event): void {
        this.filterByStatuses = Object(e)[FilteringKeys.Status] ? Object(e)[FilteringKeys.Status] : [];
        this.filterByCategory = Object(e)[FilteringKeys.Category] ? Object(e)[FilteringKeys.Category] : [];
        this.filterByTechnology = Object(e)[FilteringKeys.Technology] ? Object(e)[FilteringKeys.Technology] : [];
        this.filterByApplicationType = Object(e)[FilteringKeys.ApplicationType]
          ? Object(e)[FilteringKeys.ApplicationType]
          : [];

        const filters = [
          this.filterByStatuses,
          this.filterByCategory,
          this.filterByTechnology,
          this.filterByApplicationType,
        ];

        this.filtersActive = filters.some((item: any) => item.length > 0);
        this.getUseCases();
    }
}
</script>

<style lang='less'>
@import '../../variables.less';
.app-container {
    padding: 2rem 3.5rem 2rem 3.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    font-family: Roboto, sans-serif;
    .wrapper-modules {
        justify-content: center;
        margin: 0;
        .filter-row {
            position: relative;
            display: flex;
            flex-direction: row;
            .wrapper-search {
                flex: 3;
            }
            .wrapper-filter {
                flex: 3;
                margin-right: 14rem;
            }
        }
    }
    .wrapper-paging {
        flex-wrap: wrap;
        margin: 0 8%;
        justify-content: flex-end;
        .page-size-container {
            align-items: center;
            select {
                padding: 0.8rem;
                font-size: 1.6rem;
            }
        }
    }
    .app-card-favorites-container {
        font-family: Roboto, sans-serif;
        border-bottom: 1px solid #cccccc;
        padding: 2.4em;
        text-align: center;
        .flex-row {
            justify-content: center;
        }
        .app-card-title {
            justify-content: center;
        }
        .app-card-item {
            background-color: white;
        }
    }
    .app-filter-container {
        height: 100%;
        flex: 0 0 15%;
        border: 1px solid #e5e9ec;
        margin: 1em;
        .app-filter-title {
            font-family: Roboto, sans-serif;
            font-size: large;
            padding: 0.4rem;
            color: #777a7b;
            background-color: @background-grey;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
            font-size: large;
        }
        .app-filter-checkboxes {
            padding: 0.96rem 0.64rem;
            .app-filter-checkbox {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
        label {
            font-family: Roboto, sans-serif;
            font-size: smaller;
            padding: 0.16rem;
            line-height: 2;
            color: #777a7b;
        }
    }
    .filters {
        justify-content: center;
        padding: 1.6rem 1.6rem 0rem 1.6rem;
        display: flex;
        flex-wrap: wrap;
    }

    @media (max-width: 768px) {
        .wrapper-paging {
            justify-content: center;
            position: relative;
        }
    }

    .pui-filter {
        width: 100%;
        height:3.7rem;

        /deep/ .pui-filter__toolbar {
            display: flex;
            align-items: flex-end;


            .pui-filter-more-button {
                width: 10rem;

                .pui-filter-button {
                    width: 100%;
                    height:3.2rem;

                    .pui-filter-button__button {
                        height: 4.2rem;
                    }

                    div {
                        line-height: 4rem;
                    }
                }
            }
            .pui-filter__toolbar__item:last-child {
                margin: 0 0 -.2rem 2rem;
            }
        }
    }

    .pui-breadcrumb-item {
      a {
        padding-left: 0rem;
      }
    }
}
</style>
