<template>
    <div class="flex-row wrapper-boxes">
        <div class="flex-col box-fields">
            <div class="flex-row">
                <div class="flex-col wrapper-info">
                    <div class="title-box">
                        {{$t('getInTouch')}}
                    </div>
                    <div class="mt-1">
                        {{$t('hearSuggestions')}}
                    </div>
                </div>
            </div>
            <div class="flex-row flex-wrap">
                <div class="flex-col flex-1 input-wrapper">
                    <label for="name">
                        {{$t('name')}}
                        <span>{{$t('required')}}</span>
                    </label>
                    <input
                        :disabled="userNameIsAvailable"
                        type="text"
                        v-model="formContact.name.value"
                        id="name"
                        :class="['input-form', formContact.name.isInvalid ? 'warning-label' : '']"
                        @input="checkInput(formContact.name)"
                        @blur="checkInput(formContact.name)"
                    />
                </div>
            </div>
            <div class="flex-row flex-wrap">
                <div class="flex-col flex-1 input-wrapper">
                    <label for='email'>
                        {{$t('emailAddress')}}
                        <span>{{$t('required')}}</span>
                    </label>
                    <input
                        :disabled="userEmailIsAvailable"
                        type="text"
                        v-model="formContact.email.value"
                        id="email" :class="['input-form', formContact.email.isInvalid ? 'warning-label' : '']"
                        @input="checkInput(formContact.email)"
                        @blur="checkInput(formContact.email)"
                    />
                </div>
                <div class="flex-col flex-1 input-wrapper">
                    <label for="phone">
                        {{$t('phoneNumber')}}
                        <span>{{$t('optional')}}</span>
                    </label>
                    <input
                        type="text"
                        v-model="formContact.phone.value"
                        id="phone"
                        @input="checkInput(formContact.phone)"
                        @blur="checkInput(formContact.phone)"
                        :class="['input-form', formContact.phone.isInvalid ? 'warning-label' : '']"
                        class="input-form"/>
                </div>
            </div>
            <div class="flex-row flex-wrap">
                <div class="flex-col flex-1 input-wrapper">
                    <label for="applications">
                        {{$t('application')}}
                        <span>{{$t('required')}}</span>
                    </label>
                    <input
                      class="input-form"
                      disabled
                      v-model="appName"
                    />
                </div>
            </div>
            <div class="flex-row">
                <div class="flex-col full-width input-wrapper">
                    <div class="flex-row justify-space-between">
                      <label for="message">
                        {{$t('message')}}
                        <span>{{$t('required')}}</span>
                        </label>
                      <label class="count">{{formContact.message.value.length + '/' + textLengthConstraints.CONTACT_FORM_TEXT_LENGTH}}</label>
                    </div>
                    <textarea
                        id="message"
                        v-model="formContact.message.value"
                        :maxLength="textLengthConstraints.CONTACT_FORM_TEXT_LENGTH"
                        class="textarea"
                        @input="checkInput(formContact.message)"
                        @blur="checkInput(formContact.message)"
                        :class="['input-form', formContact.message.isInvalid ? 'warning-label' : '']">
                    </textarea>
                </div>
            </div>
            <div class="flex-row">
                <div class="flex-col full-width input-wrapper">
                    <label for="applications">
                        {{$t('feedbackAttachment.label')}}
                    </label>
                    <pui-form-group
                        :has-label-padding="false"
                        :is-valid="!formContact.file.isInvalid"
                        label=""
                        label-for="file"
                    >
                        <pui-form-file
                            id="file"
                            :label="$t('feedbackAttachment.buttonCta')"
                            @puiFormFile:added="onFileAdded"
                            @puiFormFile:removed="onFileRemoved"
                        />
                        <template #error-message>
                            {{ formContact.file.error }}
                        </template>
                    </pui-form-group>
                </div>
            </div>
            <div class="flex-row">
                <div class="flex-col full-width input-wrapper submit-btn">
                    <pui-button
                        :disabled="!formIsSubmittable || isSent"
                        @click="submitForm()">
                        {{$t('submit')}}
                    </pui-button>
                </div>
            </div>
        </div>
        <div class="flex-col box-support">
            <div class="flex-row">
                <div class="flex-col wrapper-info">
                    <div class="title-box">
                        {{$t('support')}}
                    </div>
                    <a class="email-support" :href="`mailto:${supportContactEmail}`">
                        <span>
                            <font-awesome-icon icon="envelope" />
                        </span>
                        {{supportContactEmail}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang='ts'>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ModuleApplicationService } from '../../services';
import { Form, FieldForm, ApplicationForm, Application } from '../../models';
import TextLengthConstraints from '@/utils/text-length-constraints';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';
import MessageHandler from '@/utils/message-handler';

@Component({
    name: 'send-feedback',
})

export default class SendFeedback extends Vue {
    private readonly MAX_FILE_SIZE = 4 * 1000 * 1000;

    /**
     * This prop is required to know the selected usecase ID for further processing.
     */
    @Prop()
    private useCaseId!: string;

    private textLengthConstraints = TextLengthConstraints;
    private userWatchCancellationFn?: () => void = undefined;

    private applicationService: ModuleApplicationService = new ModuleApplicationService();
    private isSent = false;

    private uploadedFile: File | null = null;

    private formContact: Form = {
        name: {
            value: this.userName,
            type: 'name',
            isInvalid: false,
        },
        email: {
            value: this.userEmail,
            type: 'email',
            isInvalid: false,
        },
        application: {
            value: '',
            type: 'application',
            isInvalid: false,
        },
        request: {
            value: this.$t('sendFeedback') as string,
            type: 'request',
            isInvalid: false,
        },
        phone: {
            value: '',
            type: 'phone',
            isInvalid: false,
        },
        message: {
            value: '',
            type: 'message',
            isInvalid: false,
        },
        file: {
            value: '',
            type: 'file',
            isInvalid: false,
            error: '',
        },
    };

    private userNameIsAvailable = false;
    private userEmailIsAvailable = false;

    private get userEmail(): string {
      return this.$store.getters?.user?.email ?? '';
    }

    private get userName(): string {
      const name = this.$store.getters?.user?.name;
      return name?.length ? name?.trim().replace(',', '') : ''
    }

    private created() {
        this.userWatchCancellationFn = this.$store.watch(
            (state, getters) => getters.user,
            (user) => {
                if (user?.name) {
                    this.formContact.name.value = user.name?.trim().replace(',', '');
                    this.userNameIsAvailable = true;
                }
                if (user?.email) {
                    this.formContact.email.value = user.email?.trim();
                    this.userEmailIsAvailable = true;
                }
            },
        );
    }

    private get supportContactEmail() {
      return this.applications.find(
        (app: Application) => Number(app.id) === Number(this.useCaseId),
      )?.supportContactEmail ?? 'coodeteam@uniper.energy';
    }

    private get appName() {
      const appName = this.applications.find(
        (app: Application) => Number(app.id) === Number(this.formContact.application.value),
      )?.name;

      if (!appName) {
        return this.useCaseId === process.env.VUE_APP_USE_CASE_ID
          ? 'App Store'
          : '';
      }

      return appName;
    }

    private unmounted() {
        if (this.userWatchCancellationFn) {
            this.userWatchCancellationFn();
        }
    }

    private get isSendingFeedback() {
      if (this.$route && this.$route.query.isFeedback === 'true') {
        return true;
      }

      return false;
    }

    get applications() {
        let applications = this.$store.getters.applications;
        const maxLengthName = 40;
        if (applications) {
            applications = applications.map((application: Application) => {
                if (application.name && application.name.length > maxLengthName) {
                    application.name =  application.name.substr(0, maxLengthName) + '...';
                }
                return application;
            });
            if (this.useCaseId && this.useCaseId.length > 0) {
                this.formContact.application.value = this.useCaseId.toString();
            }
        }
        return applications;
    }

    private async onFileAdded(payload: File[]): Promise<void> {
        if (payload.length === 0) {
            return;
        }

        this.uploadedFile = payload[0];
    }

    private onFileRemoved(): void {
        this.uploadedFile = null;
        this.checkInput(this.formContact.file);
    }

    private checkInput(field: FieldForm) {
        this.isSent = false;

        if (/^\s/.test(field.value)) {
            field.value = '';
            return;
        }

        if (field.type === 'name') {
            field.isInvalid = !this.validateName(field.value);
        }
        if (field.type === 'email') {
            field.isInvalid = !this.validateEmail(field.value);
        }
        if (field.type === 'phone') {
            field.isInvalid = field.value ? !this.validatePhoneNumber(field.value) : false;
        }
        if (field.type === 'message') {
            field.isInvalid = !this.validateMessage(field.value);
        }
        if (field.type === 'file') {
            this.validateFile(field);
        }
    }

    private validateMessage(message: string) {
        return message.length && message.length <= this.textLengthConstraints.CONTACT_FORM_TEXT_LENGTH;
    }

    private validateName(name: string) {
        const re = /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/i;
        return re.test(String(name).toLowerCase()) && name.length <= 50;
    }

    private validateEmail(email: string) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase()) && email.length <= 50;
    }

    private validatePhoneNumber(phoneNumber: string) {
        const re = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g;
        return re.test(String(phoneNumber)) && phoneNumber.length <= 20;
    }

    private validateFile(fileField: FieldForm): void {
        fileField.isInvalid = false;
        fileField.error = '';

        if (!this.uploadedFile) {
            return;
        }

        if (this.uploadedFile.size > this.MAX_FILE_SIZE) {
            fileField.isInvalid = true;
            fileField.error = this.$t('incidentForm.file.errors.sizeExceeded') as string;
            return;
        }
    }

    private async submitForm() {
        this.$store.commit('loading', true);
        let allValid = true;
        Object.values(this.formContact).forEach( (field: FieldForm) => {
            if (field.isInvalid !== null) {
                this.checkInput(field);
                if (field.isInvalid) {
                    allValid = false;
                }
            }
        });
        if (allValid) {
            const application: ApplicationForm = {
                useCaseId: Number(this.formContact.application.value),
                contactName: this.formContact.name.value,
                contactEmail: this.formContact.email.value,
                inquiryType: this.formContact.request.value,
                message: this.formContact.message.value,
                contactPhoneNumber: this.formContact.phone.value,
            };
            try {
              const response = await this.applicationService.submitApplication(application, this.uploadedFile ?? undefined);
              if (response.statusCode === 200) {
                this.isSent = true;
                MessageHandler.showToastMessage(
                  this.$t('successfully') as string,
                  ToastMessageTypes.SUCCESS
                );
              }
            } catch {
              this.$emit('onSendFeedbackError');
            }
        }
        this.$store.commit('loading', false);
    }

    private get formIsSubmittable(): boolean {
        const formValues = Object.values(this.formContact);
        return formValues.filter((value) => value.type !== 'phone' && value.type !== 'file')
                .every(((value) => value.value))
            && formValues.every((value) => value.isInvalid === false);
    }
}
</script>

<style scoped lang='less'>
@import '../../variables.less';

.contact-page-container {
    max-width: 125rem;
    margin: 1.6rem auto;
    font-family: Roboto, sans-serif;
    @media screen and (max-width: 699px) {
        margin: 1em 1em;
    }
    h1 {
        margin: 1rem;
    }
    .wrapper-boxes {
        flex-wrap: wrap;
        .input-wrapper {
            margin: 0.8rem;
            min-width: 24rem;
        }
        .single-input-col {
            flex: 0 0 48%;
            @media screen and (max-width: 970px) {
                flex: 1;
            }
        }
        .wrapper-info {
            margin: 0.8rem;
        }
        .title-box {
            font-size: 2rem;
            font-weight: bold;
            margin-bottom: 0.2rem;
        }
        .box-fields {
            flex: 2;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 1px 8px 0 rgba(0,0,0,.16);
            padding: 1.6rem;
            margin: 0.8rem;
        }
        .box-support {
            flex: 1;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 1px 8px 0 rgba(0,0,0,.16);
            padding: 1.6rem;
            height: 100%;
            margin: 0.8rem;
            .email-support {
                color: @uniper-blue;
                text-decoration: none;
            }
            .wrapper-info > div, a {
                padding: 0.32rem 0;
                &.schedule-support {
                    color: @medium-grey;
                    padding-left: 3.2rem;
                }
            }
            span {
                padding: 0.48rem;
                color: @dark-grey;
            }
        }
        label {
            font-size: 1.6rem;
            padding-bottom: 0.8rem;
            span {
                color: @warm-grey;
                padding-left: 0.8rem;
            }
        }
        input.input-form, select.input-form, textarea.input-form {
            font-size: 1.6rem;
            padding: 0.9rem 1.5rem 0.8rem 1.5rem;
            outline: none;
            border: solid 1px #adadad;
            border-radius: 0.4rem;
            &:hover {
                border: solid 1px @uniper-blue;
            }
        }
        .textarea {
            resize: none;
            font-size: 1.6rem;
            height: 28rem;
            outline: none;
            border: solid 1px #adadad;
            border-radius: 0.4rem;
            &:hover {
                border: solid 1px @uniper-blue;
            }
        }
        .submit-btn {
            align-items: flex-end;
            .btn {
                color: white;
                background-color: @uniper-blue;
                border-radius: 3.2rem;
                border: 1px solid #0078DC;
                padding: 0.64rem 5.12rem;
                max-width: 16rem;
                text-align: center;
                margin-top: 1.6em;
                cursor: pointer;
                line-height: 1.5;
                &:hover {
                    background-color: @dark-blue;
                }
            }
        }
        .warning-label {
            border: 1px solid red;
            background-color: #ffe7e9;
            option {
                background-color: white;
            }
        }
        .success-sent {
            padding: 1.6rem;
        }
    }
    .count {
      color: @warm-grey;
    }
}

/deep/ .pui-form-file__files-wrapper {
    align-items: flex-start;
}

/deep/ .pui-form-file > .pui-button {
    margin-bottom: 1rem;
}

.pui-form-file {
  padding-left: 0;
}
</style>
