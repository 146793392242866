<template>
  <div class="app-card-container pt-4 mt-1 mb-2">
    <vertical-list-transition
      v-if="filteredUseCases"
    >
      <div v-if="!filtersApplied" key="all-categories">
        <category-card
          v-for="(category, index) in categories"
          :useCases="getAppsForCategory(category)"
          :categoryName="category.name"
          :hasDivider="index !== categories.length - 1"
          :key="`${category}${index}`"
          @trigger-open-details-modal="toggleModalInMainPage"
        />
      </div>
      <div key="search-results">
        <category-card
          v-if="filtersApplied"
          :useCases="filteredUseCases"
          :categoryName="$t('results')"
          :hasDivider="false"
          key="custom"
          @trigger-open-details-modal="toggleModalInMainPage"
        />
      </div>
    </vertical-list-transition>
    <pui-loader-error
      v-if="!filteredUseCases.length"
      :title="$t('noAppsFound')"
      icon="error-empty-data"
    />
  </div>
</template>

<script lang='ts'>
import { Component, Vue, Prop } from 'vue-property-decorator';
import Header from '@/components/header/header.vue';
import VerticalListTransition from '@/components/transitions/vertical-list-transition/vertical-list-transition.vue';
import { Category, StatusEnum, UseCase } from '@/models';
import CategoryCard from '@/components/category-card/category-card.vue';

@Component({
    name: 'app-grid',
    components: {
        uiHeader: Header,
        verticalListTransition: VerticalListTransition,
        categoryCard: CategoryCard,
    },
})
export default class AppGrid extends Vue {
    /**
     * Recieves usecase data.
     */
    @Prop()
    private filteredUseCases!: UseCase[];

    @Prop()
    private filtersApplied!: boolean;

    private statusEnum = StatusEnum;

    private getAppsForCategory(category: Category): UseCase[] {
      return this.filteredUseCases.filter(
        (useCase: UseCase) =>
        this.getCategoryIdFromUseCase(useCase) === category.id &&
        useCase.status !== this.statusEnum.NotShownInUi,
      );
    }

    private getCategoryIdFromUseCase(useCase: UseCase): number {
      return useCase.mainCategoryId ? useCase.mainCategoryId : useCase.categories[0].id;
    }

    get categories(): [] {
      return this.$store.state.categories;
    }

    private categoryHasApps(category: Category): boolean {
      return this.getAppsForCategory(category).length > 0;
    }

    private toggleModalInMainPage(useCase: UseCase): void {
      this.$emit('open-details-modal', useCase);
    }
}
</script>

<style lang='less'>
@import '../../../variables.less';
.app-card-container {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0.1rem 0.4rem 0 @warm-grey;
    .logo-app {
        width: 8rem;
        height: 8rem;
    }
}
</style>
