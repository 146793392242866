import { Notification } from '@/models';

const formatDatetime = (isoString: string): string => {
    return new Date(isoString).toLocaleDateString('en-GB');
}

export const mapNotification = (n: Notification, useCases: any): any => {

    if (n.type === 'Notification') {
        return ({
            id: n.id,
            isRead: n.isRead,
            title: n.title,
            description: n.description,
            created: formatDatetime(n.timestamp),
            type: 0,
            useCaseName: useCases.find((u: any) => u.id === n.useCaseId)?.name,
            notification: n,
        });
    }

    if (n.type === 'Task') {
        return ({
            id: n.id,
            isRead: n.isRead,
            title: n.title,
            description: n.description,
            created: formatDatetime(n.timestamp),
            type: 1,
            useCaseName: useCases.find((u: any) => u.id === n.useCaseId)?.name,
            approve: () => console.log('Approved'),
            reject: () => console.log('Rejected'),
            notification: n,
        });
    }

    if (n.type === 'TaskWithRedirectToApp') {
        return ({
            id: n.id,
            isRead: n.isRead,
            title: n.title,
            description: n.description,
            created: formatDatetime(n.timestamp),
            type: 2,
            useCaseName: useCases.find((u: any) => u.id === n.useCaseId)?.name,
            redirectUrl: n.actions[0].redirectUrl,
            redirect: () => console.log('Redirected'),
            notification: n,
        });
    }
};

