import { ApiResponse, UserGroup, User, UserFilterModel, ApiResponseList } from '@/models';
import sdk from '@/utils/fe-sdk/sdk';

export class UamService {
    public async getUserGroups(useCaseId: number): Promise<ApiResponse<ApiResponseList<UserGroup>>> {
        return (await sdk?.core.userAccessManagement.request.api
            .get<ApiResponse<ApiResponseList<UserGroup>>>(`/user-groups/use-cases/${useCaseId}`)).data;
    }

    public async getUsers(userFilters: UserFilterModel): Promise<ApiResponse<ApiResponseList<User>>> {
        return (await sdk?.core.userAccessManagement.request.api
            .get<ApiResponse<ApiResponseList<User>>>(
                `/users?
                SortColumn=${userFilters.sortColumn}
                &SortDirection=${userFilters.sortDirection}
                &page=${userFilters.page}
                &size=${userFilters.size}
                &Term=${userFilters.term}
            `)).data;
    }
}
