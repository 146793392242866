export default class RichTextFormatter {

    public static formatText(text: string): string {
        return ((text.split('</p>')[0]).replace( /(<([^>]+)>)/ig, ''))
        .replace('&nbsp;','') // Replace &nbsp; with no space ''
        .replace('&amp;', '&') // Replace &amp; with &
        .replace('&lt;', '<') // Replace &lt; with <
        .replace('&gt;', '>') // Replace &gt; with >
        .replace('&quot;', '"') // Replace &quot; with "
        .replace('&apos;', "'") // Replace &apos; with '
        .replace('&hellip;', '...') // Replace &hellip; with ...
        .replace('&mdash;', '—') // Replace &mdash; with —
        .replace('&ndash;', '–'); // Replace &ndash; with –
    }

    public static getShortenedFormattedText(description: string, maxCharCount: number): string {
        if (description.length > maxCharCount) {
            return description.slice(0, maxCharCount) + '...';
        }
        return description;
    }
}
