<template>
    <div class="contact-page-container">
        <div class="flex-row mb-2" v-if="!requestErrorHappened">
            <h2>{{ getPageTitle() }}</h2>
        </div>
        <template v-if="!requestErrorHappened">
            <send-feedback
                v-if="isSendingFeedback"
                :useCaseId="useCaseId"
                @onSendFeedbackError="handleRequestError"
            />
            <template v-else>
                <request-access-external-app
                    v-if="isUseCaseExternal"
                    :useCaseId="useCaseId"
                    @onRequestAccessError="handleRequestError"
                />
                <request-access-internal-app
                    v-else
                    :useCaseId="useCaseId"
                    :userAlreadyHasAccess="userAlreadyHasAccess"
                    @onRequestAccessError="handleRequestError"
                />
            </template>
        </template>
        
        <pui-loader-error
            v-if="requestErrorHappened"
            class="contact-page-container__error"
            :title="$t('errorRequestTitle')"
            :message="$t('errorRequestMessage')"
            icon="error-not-available"
            :global="true"
            :buttons="[{ small: 'true', label: $t('tryAgain'), onClick: () => this.requestErrorHappened = false }]"
        />
    </div>
</template>


<script lang='ts'>
import { Component, Vue, Prop } from 'vue-property-decorator';
import RequestAccessInternalApp from '@/components/request-access/request-access-internal-app.vue';
import RequestAccessExternalApp from '@/components/request-access/request-access-external-app.vue';
import SendFeedback from '@/components/send-feedback/send-feedback.vue';

@Component({
    name: 'contact-page',
    components: {
        requestAccessInternalApp: RequestAccessInternalApp,
        requestAccessExternalApp: RequestAccessExternalApp,
        sendFeedback: SendFeedback,
    },
})
export default class ContactPage extends Vue {
    /**
     * This prop is required to know the selected usecase ID for further processing.
     */
    @Prop()
    private useCaseId!: string;

    private requestErrorHappened = false;

    private get isSendingFeedback() {
        if (this.$route && this.$route.query.isFeedback === 'true') {
            return true;
        }

        return false;
    }

    private get userAlreadyHasAccess() {
        if (this.$route && this.$route.query.hasAccess === 'true') {
            return true;
        }

        return false;
    }

    private get isUseCaseExternal() {
        if (this.$route && this.$route.query.type === 'External') {
            return true;
        }

        return false;
    }

    private handleRequestError() {
        this.requestErrorHappened = true;
    }

    private getPageTitle(): string {
      return this.$route.query.isFeedback ? this.$t('feedbackForm') as string : this.$t('requestAccess') as string;
    }
}
</script>

<style scoped lang='less'>
@import '../../variables.less';

.contact-page-container {
    max-width: 125rem;
    margin: 4.6rem auto;
    font-family: Roboto, sans-serif;
    @media screen and (max-width: 699px) {
        margin: 1em 1em;
    }
    h1 {
        margin: 1rem;
    }
    &__error {
        margin-top: 16rem;
    }
    .wrapper-boxes {
        flex-wrap: wrap;
        .input-wrapper {
            margin: 0.8rem;
            min-width: 24rem;
        }
        .single-input-col {
            flex: 0 0 48%;
            @media screen and (max-width: 970px) {
                flex: 1;
            }
        }
        .wrapper-info {
            margin: 0.8rem;
        }
        .title-box {
            font-size: 2rem;
            font-weight: bold;
            margin-bottom: 0.2rem;
        }
        .box-fields {
            flex: 2;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 1px 8px 0 rgba(0,0,0,.16);
            padding: 1.6rem;
            margin: 0.8rem;
        }
        .box-support {
            flex: 1;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 1px 8px 0 rgba(0,0,0,.16);
            padding: 1.6rem;
            height: 100%;
            margin: 0.8rem;
            .email-support {
                color: @uniper-blue;
                text-decoration: none;
            }
            .wrapper-info > div, a {
                padding: 0.32rem 0;
                &.schedule-support {
                    color: @medium-grey;
                    padding-left: 3.2rem;
                }
            }
            span {
                padding: 0.48rem;
                color: @dark-grey;
            }
        }
        label {
            font-size: 1.6rem;
            padding-bottom: 0.8rem;
            span {
                color: @warm-grey;
                padding-left: 0.8rem;
            }
        }
        input.input-form, select.input-form {
            font-size: 1.6rem;
            padding: 0.9rem 1.5rem 0.8rem 1.5rem;
            outline: none;
            border: solid 1px #adadad;
            border-radius: 0.4rem;
            &:hover {
                border: solid 1px @uniper-blue;
            }
        }
        .textarea {
            resize: none;
            font-size: 1.6rem;
            height: 12rem;
            outline: none;
            border: solid 1px #adadad;
            border-radius: 0.4rem;
            &:hover {
                border: solid 1px @uniper-blue;
            }
        }
        .submit-btn {
            align-items: flex-end;
            .btn {
                color: white;
                background-color: @uniper-blue;
                border-radius: 3.2rem;
                border: 1px solid #0078DC;
                padding: 0.64rem 5.12rem;
                max-width: 16rem;
                text-align: center;
                margin-top: 1.6em;
                cursor: pointer;
                line-height: 1.5;
                &:hover {
                    background-color: @dark-blue;
                }
            }
        }
        .warning-label {
            border: 1px solid red;
            background-color: #ffe7e9;
            option {
                background-color: white;
            }
        }
        .success-sent {
            padding: 1.6rem;
        }
    }

}
</style>
