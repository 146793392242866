import { Category } from '@/models/category';

export interface UseCase {
    allowUsersInMultipleGroups: boolean;
    description: string;
    external: boolean;
    id: number;
    identityClientId: string;
    logo: string;
    logoUrl: string;
    name: string;
    ownerEmail: string;
    ownerName: string;
    ownerPhoneNumber: string;
    status: number;
    shortDescription: string | null;
    url: string;
    hasAccess: boolean;
    applicationType: string;
    useCaseType: string;
    mainCategoryId?: number;
    categories: Category[];
    ownerGroupId?: string;
    resolverGroupId?: string;
    businessServiceId?: string;
    serviceOfferingId?: string;
    isServiceNowConfigured: boolean;
    supportContactEmail?: string;
}

export enum ApplicationType {
    WebApplication = 1,
    ReportApplication = 2,
    PowerAppApplication = 3,
    MobileApplication = 4,
}

export interface UseCasePaged {
    items: UseCase[];
    totalCount: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}
