import { ApiResponse, Technology, ApiResponseList } from '@/models';
import SdkMetaDataApiHelper from '@/utils/fe-sdk/sdk-meta-data-api-helper';

export class TechnologyService {
    private api = new SdkMetaDataApiHelper('technologies');

    public async getTechnologies(): Promise<ApiResponse<ApiResponseList<Technology>>> {
        return (await this.api.get()).data as Promise<ApiResponse<ApiResponseList<Technology>>>;
    }
}
