<template>
    <div class="flex-row wrapper-boxes">
        <div class="flex-col box-fields">
            <div class="flex-row">
                <div class="flex-col wrapper-info">
                    <div>{{ $t('requestAccessForExternalApplication') }}</div>
                </div>
            </div>
            <pui-form @submit.prevent="submitForm()" class="external-app-access-form">
                <div v-pui-form-grid-row>
                    <pui-form-group
                        :label="$t('incidentForm.useCase.name')"
                        v-pui-form-grid-column="12"
                    >
                        <pui-form-input-field
                            is-disabled
                            :value="application.name"
                        />
                    </pui-form-group>
                </div>
                <div v-pui-form-grid-row>
                    <pui-form-group
                        :label="$t('incidentForm.title.name')"
                        label-for="title"
                        :is-valid="!incidentForm.title.isInvalid"
                        show-required-label
                        v-pui-form-grid-column="12"
                    >
                        <pui-form-input-field
                            id="title"
                            v-model="incidentForm.title.value"
                            :placeholder-text="$t('incidentForm.title.name')"
                            :is-valid="!incidentForm.title.isInvalid"
                            :is-disabled="isInputDisabled"
                            is-required
                            autofocus
                        />
                        <template #error-message>
                            {{ incidentForm.title.error }}
                        </template>
                    </pui-form-group>
                </div>
                <div v-pui-form-grid-row>
                    <pui-form-group
                        :label="$t('incidentForm.body.name')"
                        label-for="body"
                        show-required-label
                        :is-valid="!incidentForm.body.isInvalid"
                        v-pui-form-grid-column="12"
                    >
                        <pui-form-textarea
                            id="body"
                            v-model="incidentForm.body.value"
                            :is-valid="!incidentForm.body.isInvalid"
                            :is-disabled="isInputDisabled"
                            :rows="6"
                            grow
                            is-required
                        />
                        <template #error-message>
                            {{ incidentForm.body.error }}
                        </template>
                    </pui-form-group>
                </div>
                <div v-pui-form-grid-row>
                    <pui-form-group
                        :label="$t('incidentForm.file.name')"
                        label-for="file"
                        :is-valid="!incidentForm.file.isInvalid"
                        v-pui-form-grid-column="12"
                    >
                        <pui-form-file
                            id="file"
                            :label="$t('incidentForm.file.buttonCta')"
                            :disabled="isInputDisabled"
                            @puiFormFile:added="onFileAdded"
                            @puiFormFile:removed="onFileRemoved"
                        />
                        <template #error-message>
                            {{ incidentForm.file.error }}
                        </template>
                    </pui-form-group>
                </div>
                <div
                    class="flex-row justify-end items-center mt-2"
                    v-pui-form-grid-row
                >
                    <pui-button
                        :disabled="hasRequestedAccess"
                    >
                        {{ $t('requestAccess') }}
                    </pui-button>
                </div>
            </pui-form>
        </div>
        <div class="flex-col box-support">
            <div class="flex-row">
                <div class="flex-col wrapper-info">
                    <div class="title-box">{{$t('support')}}</div>
                    <a class="email-support" href="mailto:coodeteam@uniper.energy">
                        <span>
                            <font-awesome-icon icon="envelope" />
                        </span>
                        {{$t('emailSupport')}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang='ts'>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ModuleApplicationService } from '../../services';
import { FieldForm, UseCase, Form } from '../../models';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';
import MessageHandler from '@/utils/message-handler';
import { mapGetters } from 'vuex';
import { ApplicationIncidentForm } from '@/models/form';
import sdk from '@/utils/fe-sdk/sdk';
import { LogLevel, SeverityLevel } from '@coode/fe-sdk';

@Component({
    name: 'request-access-external-app',
    computed: {
        ...mapGetters(['useCases'])
    },
})

export default class RequestAccessExternalApp extends Vue {
    private readonly MAX_FILE_SIZE = 4 * 1000 * 1000;

    /**
     * This prop is required to know the selected usecase ID for further processing.
     */
    @Prop()
    private useCaseId!: string;

    private applicationService: ModuleApplicationService = new ModuleApplicationService();

    private hasRequestedAccess = false;
    private uploadedFile: File | null = null;

    private incidentForm: Form = {
        title: {
            value: '',
            type: 'title',
            isInvalid: false,
            error: '',
        },
        body: {
            value: '',
            type: 'message',
            isInvalid: false,
            error: '',
        },
        file: {
            value: '',
            type: 'file',
            isInvalid: false,
            error: '',
        },
    };

    private get application(): UseCase | undefined {
        return this.$store.getters.applications
            .find((useCase: UseCase) => useCase.id === parseInt(this.useCaseId, 10));
    }

    private get isLoading(): boolean {
        return this.$store.getters.isLoading;
    }

    private get isInputDisabled(): boolean {
        return this.isLoading || this.hasRequestedAccess;
    }

    private async onFileAdded(payload: File[]): Promise<void> {
        if (payload.length === 0) {
            return;
        }

        this.uploadedFile = payload[0];
    }

    private onFileRemoved(): void {
        this.uploadedFile = null;

        this.checkInput(this.incidentForm.file);
    }

    private checkInput(field: FieldForm): void {
        field.isInvalid = false;
        field.error = '';

        switch (field.type) {
            case 'title':
                this.validateTitle(field);
                break;
            case 'message':
                this.validateMessage(field);
                break;
            case 'file':
                this.validateFile(field);
        }
    }

    private validateTitle(titleField: FieldForm): void {
        if (titleField.value === '') {
            titleField.isInvalid = true;
            titleField.error = this.$t('incidentForm.title.requiredError') as string;
            return;
        }

        if (titleField.value.length >= 160) {
            titleField.isInvalid = true;
            titleField.error = this.$t('incidentForm.title.tooLongError') as string;
        }
    }

    private validateMessage(messageField: FieldForm): void {
        if (messageField.value === '') {
            messageField.isInvalid = true;
            messageField.error = this.$t('incidentForm.body.requiredError') as string;
        }
    }

    private validateFile(fileField: FieldForm): void {
        if (!this.uploadedFile) {
            return;
        }

        if (this.uploadedFile.size > this.MAX_FILE_SIZE) {
            fileField.isInvalid = true;
            fileField.error = this.$t('incidentForm.file.errors.sizeExceeded') as string;
            return;
        }
    }

    private async submitForm(): Promise<void> {
        this.$store.commit('loading', true);

        let isValid = true;
        Object.values(this.incidentForm).forEach((field: FieldForm) => {
            this.checkInput(field);
            if (field.isInvalid) {
                isValid = false;
            }
        });

        if (isValid) {
            const incident: ApplicationIncidentForm = {
                useCaseId: parseInt(this.useCaseId, 10),
                title: this.incidentForm.title.value,
                body: this.incidentForm.body.value,
            };

            try {
                const response = await this.applicationService.createIncident(incident);

                if (this.uploadedFile) {
                    await this.uploadAttachment(response.result.id);
                }

                this.hasRequestedAccess = true;
                MessageHandler.showToastMessage(
                    this.$t('successfully') as string,
                    ToastMessageTypes.SUCCESS
                );

                sdk?.logger.logEvent('App access request', {
                    message: 'Submitted Access App Request',
                    useCaseId: Number(process.env.VUE_APP_USE_CASE_ID),
                    severity: SeverityLevel.Information,
                    level: LogLevel.Info,
                    category: 'UserInteraction',
                    type: 'JavaScriptWebApp'
                });
            } catch (err) {
                this.$emit('onRequestAccessError');
            }
        }

        this.$store.commit('loading', false);
    }

    private async uploadAttachment(incidentId: string): Promise<void> {
        if (!this.uploadedFile) {
            return;
        }

        try {
            await this.applicationService.uploadAttachment(incidentId, this.uploadedFile);
        } catch (err) {
            this.$emit('onRequestAccessError');
        }
    }
}
</script>

<style scoped lang='less'>
@import '../../variables.less';

.contact-page-container {
    max-width: 125rem;
    margin: 1.6rem auto;
    font-family: Roboto, sans-serif;
    @media screen and (max-width: 699px) {
        margin: 1em 1em;
    }
    h1 {
        margin: 1rem;
    }
    .wrapper-boxes {
        flex-wrap: wrap;

        .external-app-access-form {
            margin: 0 -1.8rem;
        }

        .wrapper-info {
            margin: 0.8rem;
        }
        .title-box {
            font-size: 2rem;
            font-weight: bold;
            margin-bottom: 0.2rem;
        }
        .box-fields {
            flex: 2;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 1px 8px 0 rgba(0,0,0,.16);
            padding: 1.6rem;
            margin: 0.8rem;
        }
        .box-support {
            flex: 1;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 1px 8px 0 rgba(0,0,0,.16);
            padding: 1.6rem;
            height: 100%;
            margin: 0.8rem;
            .wrapper-info > div, a {
                padding: 0.32rem 0;
                &.schedule-support {
                    color: @medium-grey;
                    padding-left: 3.2rem;
                }
            }
            span {
                padding: 0.48rem;
                color: @dark-grey;
            }
        }
        label {
            font-size: 1.6rem;
            padding-bottom: 0.8rem;
            span {
                color: @warm-grey;
                padding-left: 0.8rem;
            }
        }
    }
}
</style>
