import { render, staticRenderFns } from "./notifications-tab.vue?vue&type=template&id=1f5c2ff0"
import script from "./notifications-tab.vue?vue&type=script&lang=ts"
export * from "./notifications-tab.vue?vue&type=script&lang=ts"
import style0 from "./notifications-tab.vue?vue&type=style&index=0&id=1f5c2ff0&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports