<template>
    <div>
        <div v-if="notifications.length === 0" class="notifications-overview__no-notifications">
            <pui-icon icon-color="#BABABA" icon-name="alert" size="8rem" class="notifications-overview__no-notifications--icon"> </pui-icon>
            <pui-headline type="h2">{{ $t('noNotificationsTitle') }}</pui-headline>
            <pui-headline type="h2" looks-like="form-sheet-subtitle" class="notifications-overview__no-notifications--subtitle">{{ $t('noNotificationsSubtitle') }}</pui-headline>
        </div>
        <pui-card title="" v-for="notification in notifications" :key="notification.id" class="notifications-overview__card">
            <span class="notifications-overview__card__content--12">
                <pui-card-label-text :label="formatDatetime(notification.timestamp)" />
            </span>
            <div class="notifications-overview__card__content--20 notifications-overview__card__content__info">
                <div class="notifications-overview__card__content__info--title">
                    {{ notification.title }}
                    <div style="position: relative;">
                        <pui-icon
                            v-if="notification.tooltipItems && notification.tooltipItems.length"
                            :ref="`popover_anchor_${notification.id}`"
                            icon-color="#229AFE"
                            icon-name="info"
                            size="2rem"
                            class="notifications-overview__card__content__info--icon"
                            @mouseover.native="toggleElementDisplay(`info_popover_${notification.id}`, 'display', 'flex')"
                            @mouseleave.native="toggleElementDisplay(`info_popover_${notification.id}`, 'display', 'none')"
                        />

                        <div
                            :ref="`info_popover_${notification.id}`"
                            v-if="notification.tooltipItems"
                            class="pui-popover__wrapper notifications-overview__card__content__info--popover"
                        >
                            <div class="pui-popover light">
                                <div class="pui-popover__header"></div>
                                <div class="pui-popover__content">
                                    <div v-for="(tooltipItem, index) in notification.tooltipItems" :key="notification.id + ':tooltip:' + index" class="notifications-overview__card__content__info__wrapper">
                                        <span class="notifications-overview__card__content__info--title">
                                            {{ tooltipItem.title }}:
                                        </span>
                                        <span class="notifications-overview__card__content__info--value">
                                            {{ tooltipItem.value }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="pui-popover__arrow light top" style="right: 0%;"></div>
                            <div class="pui-popover__shadow"></div>
                        </div>
                    </div>
                </div>
                <span class="pui-card-label-text">
                    <p>{{ notification.description }}</p>
                </span>
            </div>
            <span class="pui-card-label-text notifications-overview__card__content--25" v-if="!notification.dynamicColumns" />
            <span class="pui-card-label-text notifications-overview__card__content--25" v-if="notification.dynamicColumns" style="margin-right: 1em;">
                <p class="pui-card-label-text__label"> {{ notification.dynamicColumns[0] ? notification.dynamicColumns[0].title : '' }} </p>
                <div class="notifications-overview__dynamic">
                    <span
                        v-if="notification.dynamicColumns[0].dataType === 'Text' && notification.dynamicColumns[0].isReadOnly"
                        class="pui-card-label-text notifications-overview__card__content--20 notifications-overview__card__text--bg"
                    >
                        <p>{{ notification.dynamicColumns[0].value }}</p>
                    </span>
                    <pui-form-input-field
                        v-if="notification.dynamicColumns[0].dataType === 'Text' && !notification.dynamicColumns[0].isReadOnly"
                        :value="notification.dynamicColumns[0].value"
                        :is-readonly="false"
                        :is-disabled="shouldBeDisabled"
                    />
                    <pui-form-input-field
                        v-if="notification.dynamicColumns[0].dataType === 'Numeric'"
                        :value="notification.dynamicColumns[0].value[0]"
                        :is-readonly="notification.dynamicColumns[0].isReadOnly"
                        :is-disabled="shouldBeDisabled"
                        type="number"
                    />
                    <pui-form-input-field
                        v-if="notification.dynamicColumns[0].dataType === 'Date' || notification.dynamicColumns[0].dataType === 'Time'"
                        :value="notification.dynamicColumns[0].value[0]"
                        :is-readonly="notification.dynamicColumns[0].isReadOnly"
                        :type="notification.dynamicColumns[0].dataType.toLowerCase()"
                        :is-disabled="shouldBeDisabled"
                    />
                    <pui-form-select
                        v-if="notification.dynamicColumns[0].dataType === 'SingleSelect'"
                        label=" "
                        :search-input-id="notification.id"
                        :options="generateSelectInputObjects(notification.dynamicColumns[0].availableValues)"
                        :value="notification.dynamicColumns[0].value[0]"
                        :is-readonly="notification.dynamicColumns[0].isReadOnly"
                        :is-disabled="shouldBeDisabled"
                    />
                    <pui-form-multi-select
                        v-if="notification.dynamicColumns[0].dataType === 'MultiSelect'"
                        label=" "
                        :search-input-id="notification.id.toString()"
                        :options="generateSelectInputObjects(notification.dynamicColumns[0].availableValues)"
                        v-model="notification.dynamicColumns[0].value"
                        :is-readonly="notification.dynamicColumns[0].isReadOnly"
                        :is-disabled="shouldBeDisabled"
                    />
                </div>
            </span>
            <span class="notifications-overview__card__content--12">
                <pui-card-label-text label="Tool" :text="findUsecaseById(notification.useCaseId)" />
            </span>
            <span class="pui-card-label-text notifications-overview__card__content--20" label="Actions" v-if="notification.type === 'Task'" >
                <p class="pui-card-label-text__label"> {{ $t('notificationTabs.actions') }} </p>
                <div class="notifications-overview__card__buttons">
                    <pui-button
                        class="notifications-overview__card__button"
                        :disabled="shouldBeDisabled"
                        state="primary"
                        :small="true"
                        @click="() => handleAction(notification, 0)">
                        {{ $t('notificationAction.approve') }}
                    </pui-button>
                    <pui-button
                        class="notifications-overview__card__button"
                        :disabled="shouldBeDisabled"
                        state="primary"
                        :small="true"
                        @click="() => handleAction(notification, 1)">
                        {{ $t('notificationAction.reject') }}
                    </pui-button>
                </div>
            </span>
            <span class="pui-card-label-text notifications-overview__card__content--20" label="Actions" v-if="notification.type === 'TaskWithRedirectToApp'">
                <p class="pui-card-label-text__label"> {{ $t('notificationTabs.actions') }} </p>
                <div class="notifications-overview__card__buttons">
                    <pui-button
                        class="notifications-overview__card__button"
                        :disabled="shouldBeDisabled"
                        state="primary"
                        :small="true"
                        @click="() => handleRedirect(notification)">
                        {{ $t('notificationAction.details') }}
                    </pui-button>
                </div>
            </span>
        </pui-card>
        <pui-pagination
            v-if="notifications.length > 0"
            v-model="mutableCurrentPage"
            :total-pages="totalPages"
            :range-of-pages="3"
            @changed:page="onPageChanged"
        />
    </div>
</template>

<script lang='ts'>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Notification, UseCase } from '@/models';
import { NotificationService, UseCaseService } from '@/services';
import { Action, ActionNotification, DisplayOnlyNotification, RedirectNotification } from '@/models/notification';
import { EventBus } from '@/utils';

@Component({
    name: 'notifications-tab',
    components: {
    },
})
export default class NotificationsOverviewPage extends Vue {
    @Prop({ default: [] })
    private notifications!: any[];

    @Prop({ default: 1 })
    private totalPages!: number;

    @Prop({ default: 1 })
    private currentPage!: number;

    @Prop({ default: false })
    private shouldBeDisabled!: boolean;

    private notificationService: NotificationService = new NotificationService();
    private useCaseService: UseCaseService = new UseCaseService();
    private selectedAppFilters: any[] = [];
    private notificationsLoaded = false;
    private showClearNotisToast = false;
    private mutableCurrentPage = 1;

    private get allUseCases(): any[] {
        return this.$store.getters.useCases.map((u: UseCase) => ({ displayName: u.name, value: u.id }));
    }

    private get showTabs(): boolean {
        return this.$store.getters['notifications/showTabs'];
    }

    private findUsecaseById(id: number): string {
        return this.allUseCases.find((u: any) => u.value === id)?.displayName;
    }

    private generateSelectInputObjects(applicableValues: any): any {
        if (applicableValues) {
            return applicableValues.map((v: any) => ({ label: v.name, value: v.id }));
        }
        return [];
    }

    private async handleAction(notification: any, actionId: number): Promise<any> {
       try {
            if (notification.actions && actionId === 0) {
            const foundCorrespondingAction = notification.actions.find((action: Action) => action.id === actionId);

            if (foundCorrespondingAction.includeColumnIdsInRequest) {
                const content = {} as any;

                foundCorrespondingAction.includeColumnIdsInRequest.forEach((columnId: number) => {
                    const foundDynamicColumn =
                    notification.dynamicColumns.find((column: any) => column.id === columnId);
                    content[columnId.toString()] = foundDynamicColumn.value;
                });

                return await this.notificationService.doAction(notification.workflowInstanceId, actionId, content);
            }
        }
            await this.notificationService.doAction(notification.workflowInstanceId, actionId);
        } finally {
            this.$store.dispatch('notifications/removeTask', notification);
        }
    }

    private handleRedirect(notification: any): void {
        this.$store.dispatch('notifications/markNotificationAsRead', notification);

        if (notification.actions[0].redirectUrl) {
            this.notificationService.markNotificationAsRead(notification.id);
            window.open(notification.actions[0].redirectUrl);
        }
    }

    private setShowClearNotisToast(val: boolean): void {
        this.showClearNotisToast = val;
    }

    private onPageChanged(): void {
        this.$emit('onPageChanged', this.mutableCurrentPage);
    }

    private formatDatetime(isoString: string): string {
        return new Date(isoString).toLocaleString('en-GB').replaceAll(',', '');
    }

    private toggleElementDisplay(anchorRef: string, attribute: string, value: string): void {
        const anchor = (this.$refs[anchorRef] as any)[0];

        if (anchor) {
            anchor.$el ?
                anchor.$el.style[attribute] = value :
                anchor.style[attribute] = value;
        }
    }

    private async mounted(): Promise<void> {
        this.mutableCurrentPage = this.currentPage;
    }
}
</script>

<style lang='less'>
@import '../../../variables.less';

.notifications-overview {
    .pui-form-type-ahead-input-multi-select--readonly {
         border: 1px solid #adadad !important;
         cursor: default;
    }

    &__card {
        width: 100%;
        margin-bottom: 1em;

        :nth-child(3) {
            > p.pui-card-label-text__label {
                font-weight: bold;
                color: #000;
            }
        }

        &__text--bg {
            background-color: #ededed;
            border: #cccccc 1px solid;
            border-radius: 5px;
            padding: 1rem;
        }

        &__buttons {
            display: flex;
            :nth-child(2) {
                margin-left: 1rem
            }
        }

        &__button {
            padding-left: 0 !important;
        }

        &__content {

            &--10 {
                flex: 0 0 10% !important;
            }

            &--12 {
                flex: 0 0 12% !important;
                > div {
                    flex: 0 0 13% !important;
                }
            }

            &--20 {
                flex: 0 0 20% !important;
            }

            &--25 {
                flex: 0 0 25% !important;
            }

            &__info {
                &__wrapper {
                    display: flex;
                    justify-content: space-between;
                }
                &--title {
                    font-weight: bold;
                    display: flex;
                }
                &--icon {
                    margin-left: 1rem;
                }
                &--popover {
                    display: none;
                    position: absolute;
                    left: 6px;
                    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
                }
            }
        }
    }
    &__no-notifications {
        display: grid;
        justify-items: center;
        margin-top: 16rem;
        &--icon {
            margin-bottom: 2rem;
        }
        &--subtitle {
            margin-top: 1rem;
        }
    }
}
</style>
