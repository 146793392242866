import { ApiResponse, Category, ApiResponseList } from '@/models';
import SdkMetaDataApiHelper from '@/utils/fe-sdk/sdk-meta-data-api-helper';

export class CategoryService {
    private api = new SdkMetaDataApiHelper('categories');

    public async getCategories(): Promise<ApiResponse<ApiResponseList<Category>>> {
        return (await this.api.get()).data as Promise<ApiResponse<ApiResponseList<Category>>>;
    }
}
