<template>
    <modal-component :is-large="true"
        @hide-modal="cancel">
        <template #header>
            <div class="align-header">
                <div class="app-details-container">
                    <div class="flex-row wrapper-title" @click="goToModule()">
                        <div class="flex-col">
                            <img :src="selectedUseCase.logoUrl" alt="" class="app-logo" />
                        </div>
                        <div class="flex-col">
                            <h3>{{selectedUseCase.name}}</h3>
                        </div>
                    </div>
                </div>
                <span @click="cancel()" class="exit-button">&times;</span>
            </div>
        </template>
        <template #content>
            <div class="pui-form-grid-row">
              <div class="pui-form-grid-column-4 vertical-separator">
                <div class="flex-row flex-center">
                  <pui-icon
                      icon-name="user"
                      size="20"
                  />
                  <div class="mr-2 flex-col">
                    <span class="ml-1 details-label">{{ $t('moduleOwner') + ': ' }}</span>
                    <span class="ml-1 mr-2 details-value-text">{{ moduleOwnerName }}</span>
                    <span class="vertical-separator"/>
                  </div>
                </div>
              </div>

              <div class="pui-form-grid-column-4">
                <div :class="['flex-row flex-center',{'vertical-separator': selectedUseCase.ownerPhoneNumber}]">
                  <pui-icon
                      icon-name="mail"
                      size="20"
                  />
                  <div class="mr-2 flex-row">
                    <div class="flex-col">
                      <span class="ml-1 details-label">{{ $t('emailAddress') + ': ' }}</span>
                      <a :href="`mailto:${moduleOwnerEmail}`" class="ml-1 mr-2 link details-value-text">{{ moduleOwnerEmail }}</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pui-form-grid-column-4">
                <div
                  v-if="selectedUseCase.ownerPhoneNumber"
                  class="flex-row flex-center"
                >
                  <pui-icon
                      icon-name="phone"
                      size="20"
                  />
                  <div class="flex-col">
                    <span class="ml-1 details-label">{{ $t('phoneNumber') + ': ' }}</span>
                    <span class="ml-1 details-value-text">{{ moduleOwnerPhoneNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr class="horizontal-separator">
            <div class="app-details-container wrapper-modal pl-3 pr-3 pb-3 pt-2">
                <div class="wrapper-title pb-3">
                    <div class="flex-row">
                        <h4><b>{{$t('moduleOverview')}}</b></h4>
                    </div>
                    <rich-text-editor
                      class="flex-row wrapper-details"
                      v-if="selectedUseCase.shortDescription"
                      :value="selectedUseCase.shortDescription"
                    />
                </div>
                <div class="pb-3">
                    <div class="flex-row">
                        <h4><b>{{$t('description')}}</b></h4>
                    </div>
                    <rich-text-editor
                      class="flex-row wrapper-details"
                      v-if="selectedUseCase.description"
                      :value="selectedUseCase.description"
                    />
                    <div class="flex-row wrapper-details" v-if="selectedUseCase.description && showReadMore">
                        <p>{{selectedUseCase.description}}</p>
                    </div>
                    <div
                        class="flex-row flex-wrap"
                        v-if="selectedUseCase.description && selectedUseCase.description.length > 2000 && !showReadMore">
                        <div class="btn-document" @click="showReadMore = true;">{{$t('readMore')}}</div>
                    </div>
                </div>
                <div
                  class="pb-3"
                  v-if="relatedIdeas && relatedIdeas.length"
                >
                    <div class="flex-row">
                        <h4><b>{{$t('relatedIdeas')}}</b></h4>
                    </div>
                    <ul class="ideas-list">
                      <li v-for="idea in relatedIdeas"
                        :key="idea.ideaId"
                      >
                        <pui-link
                          :title="getIdeaName(idea)"
                          :href="idea.ideaLink"
                          target="_blank"
                        />
                      </li>
                    </ul>
                </div>
                <div class="box-document-overview-right flex-col">
                    <div class="flex-row box-wrapper">
                        <div class="box-contact">
                            <div class="button-list flex-wrap">
                                <pui-button
                                  v-if="selectedUseCase.isServiceNowConfigured"
                                  class="incident-button"
                                  state="secondary"
                                  icon="warning"
                                  @click="goToIncidentPage()"
                                >
                                  {{ $t('reportIncident') }}
                                </pui-button>
                                <pui-button
                                  class="feedback-button"
                                  :disabled="false"
                                  state="secondary"
                                  icon="message"
                                  @click="goToContactPageFeedback()"
                                >
                                  {{$t('sendFeedback')}}
                                </pui-button>
                                <template v-if="shouldShowRequestAccessSection">
                                  <pui-button
                                    v-if="shouldShowRequestAccessButton"
                                    :disabled="false"
                                    state="secondary"
                                    :small="false"
                                    @click="handleRequestAccess()"
                                  >
                                    {{$t('requestAccess')}}
                                  </pui-button>
                                  <pui-button
                                    v-if="!shouldShowRequestAccessForUserButton && isReportApplication"
                                    :disabled="false"
                                    state="secondary"
                                    :small="false"
                                    @click="handleRequestAccess()"
                                  >
                                    {{$t('requestAccessForOthers')}}
                                  </pui-button>
                                </template>
                                <pui-button
                                  class="module-button"
                                  :disabled="false"
                                  state="primary"
                                  :small="false"
                                  @click="goToModule()"
                                >
                                  {{$t('goToModule')}}
                                </pui-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </modal-component>
</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalComponent from '@/components/modal-component/modal-component.vue';
import { UseCase, UserGroup } from '@/models';
import RichTextEditor from '@/components/rich-text-editor/rich-text-editor.vue';
import { ApplicationType } from '@/models/usecase';
import { RelatedUseCaseService, UamService } from '@/services';
import { RelatedIdea } from '@/models/related-idea';
import MessageHandler from '@/utils/message-handler';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';

@Component({
    name: 'app-details-modal',
    components: {
        modalComponent: ModalComponent,
        RichTextEditor,
    },
})
export default class AppDetailsModal extends Vue {
    /**
     * This prop is required to know the selected usecase for further processing.
     */
    @Prop()
    private selectedUseCase!: UseCase;
    private showReadMore = false;
    private relatedUseCaseService: RelatedUseCaseService = new RelatedUseCaseService();
    private relatedIdeas: RelatedIdea[] = [];
    private uamService: UamService = new UamService();
    private userGroups: UserGroup[] = [];

    private async mounted(): Promise<void> {
      await this.getRelatedIdeas();
    }

    get moduleOwnerName(): string {
      return this.selectedUseCase.ownerName ?? '';
    }

    get moduleOwnerEmail(): string {
      return this.selectedUseCase.ownerEmail ?? '';
    }

    get moduleOwnerPhoneNumber(): string {
      return this.selectedUseCase.ownerPhoneNumber ?? '';
    }

    private async getRelatedIdeas(): Promise<void> {
      this.relatedIdeas = await this.relatedUseCaseService.getRelatedIdeasOfUseCase(this.selectedUseCase.id);
    }

    private getIdeaName(idea: RelatedIdea): string {
      return idea?.coodeId + ' ' + idea?.ideaName;
    }

    private goToModule() {
        if (this.selectedUseCase && this.selectedUseCase.url) {
            window.open(this.selectedUseCase.url);
        }
    }

    private isAppType(type: ApplicationType): boolean {
        return this.selectedUseCase.applicationType === ApplicationType[type];
    }

    get shouldShowRequestAccessButton(): boolean {
      if (this.isReportApplication || this.isMobileApplication)
        return false
      return this.shouldShowForExternalUseCase && this.shouldShowRequestAccessForUserButton
    }

    private get shouldShowRequestAccessSection(): boolean {
        return !this.isAppType(ApplicationType.PowerAppApplication);
    }

    private get shouldShowForExternalUseCase(): boolean {
      if (this.selectedUseCase.useCaseType === 'External') {
        return this.selectedUseCase.isServiceNowConfigured;
      }

      return true;
    }

    private get shouldShowRequestAccessForUserButton(): boolean {
      if (this.selectedUseCase.name !== 'Digital Idea Tracker' &&  !this.selectedUseCase.hasAccess) {
        return true;
      }

      return false;
    }

    private get isReportApplication(): boolean {
      return this.isAppType(ApplicationType.ReportApplication);
    }

    private get isMobileApplication(): boolean {
      return this.isAppType(ApplicationType.MobileApplication);
    }

    private async getUserGroups() {
      try {
          const userGroups = (await this.uamService.getUserGroups(Number(this.selectedUseCase.id))).result.items;
          this.$store.commit('userGroups', userGroups);
          this.userGroups = userGroups;
          return userGroups;
      } catch (error) {
          MessageHandler.showToastMessage(
            this.$t('requestingAccessToThisApplication') as string,
            ToastMessageTypes.ERROR
          );
      } finally {
          this.$store.commit('loading', false);
      }
    }

    private async handleRequestAccess(): Promise<void> {
      if (this.selectedUseCase.useCaseType === 'External') {
        this.goToContactPage();
        return;
      }
      
      await this.getUserGroups();
      if (!this.userGroups.length) {
        return;
      }
      this.goToContactPage();
    }

    private goToContactPage() {
        this.$router.push({
            name: 'ContactPage',
            params: {
                useCaseId: String(this.selectedUseCase.id),
            },
            query: {
                hasAccess: String(!this.shouldShowRequestAccessForUserButton),
                type: this.selectedUseCase.useCaseType,
            },
        });
    }

    private goToContactPageFeedback() {
        this.$router.push({
            name: 'ContactPage',
            params: {
                useCaseId: String(this.selectedUseCase.id),
            },
            query: {
                isFeedback: 'true',
            },
        });
    }

    private goToIncidentPage(): void {
        this.$router.push({
            name: 'IncidentPage',
            params: {
                useCaseId: String(this.selectedUseCase.id),
            },
        });
    }

    private cancel(): void {
        this.$emit('cancel');
    }
}

</script>
<style scoped lang="less">
@import '../../variables.less';
.app-details-container {
    display: flex;
    font-family: Roboto, sans-serif;
    flex-direction: column;
    font-size: 1.9rem;
    height: 100%;
    overflow-y: auto;
    cursor: default;
    h4 {
        margin-bottom: 0.48rem;
    }
    p {
        margin: 0.16rem 0;
    }
    b {
      font-weight: 600;
    }
    .btn-document {
        color: @uniper-blue;
        border-radius: 3.2rem;
        border: 1px solid @uniper-blue;
        padding: 0.6rem 1.2rem;
        min-width: 17rem;
        text-align: center;
        margin-top: 1.6em;
        margin-right: 1.6rem;
        cursor: pointer;
        &:hover {
            color: #fff;
            background-color: @uniper-blue;
        }
    }
    .contact-label {
        padding-right: 0.8rem;
    }
    .contact-info {
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        pointer-events: none;
    }
    .box-header {
        flex: 1;
    }
    .wrapper-title {
        align-items: center;
        h3 {
            padding-left: 2.4rem;
        }
    }
    .box-document-overview-right {
        .box-details {
            display: flex;
            flex-direction: column;
            flex: 0 0 12%;
            &:nth-child(2) {
                flex: 0 0 17%;
            }
            &:first-child {
                color: #777a7b;
            }
        }
        .box-contact {
            .button-list {
                display: flex;
            }

            .title-box-contact {
                color: #777a7b;
                margin: 0.8em 0;
            }

            .feedback-button {
              border: none;
              margin-right: 3.2rem;
            }

            .incident-button {
                border: none;
                margin-right: 1.6rem;
                color: @contextual-red;
            }

            .module-button {
              margin-left: 1.6rem;
            }
        }
        @media screen and (max-width: 1320px) {
            .box-wrapper {
                justify-content: flex-start;
            }
            .box-contact {
                width: 100%;

                .button-list {
                    margin-top: 1.5rem;

                    flex-direction: column;
                    row-gap: 1rem;
                }

                button {
                    margin: 0 !important;
                }
            }
        }
        @media screen and (min-width: 1320px) {
            .box-wrapper {
                justify-content: flex-end;
            }
            .box-contact {
                .button-list {
                    flex-direction: row;
                }
            }
        }
    }
    .app-logo {
        width: 5rem;
        height: 5rem;
    }
}

.details-label {
  text-transform: uppercase;
  font-size: 1.5rem;
}

.details-value-text {
  font-size: 1.7rem;
  color: @dark-blue-medium;
}

.link {
  color: @uniper-blue;
}

.horizontal-separator {
  opacity: 0.2;
  width: 95%;
}

.vertical-separator {
  border-right: 1px solid @grey-lighter;
}

.ideas-list {
  margin: 0;
  li {
    line-height: 1.5;
    border-bottom: none;
  }
}
</style>
