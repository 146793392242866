<template>
    <div>
        <fade-transition :appear="true" :fast="true">
            <div class="repository-modal-wrapper">
                <div class="repository-modal" :class="{'modal-large':isLarge}">
                    <div class="repository-modal-header">
                        <slot name="header"></slot>
                    </div>
                    <div class="repository-modal-content">
                        <slot name="content"></slot>
                    </div>
                    <div class="repository-modal-footer" v-if="$slots['footer']">
                        <slot name="footer"></slot>
                    </div>
                </div>
                <div class="outside-modal"
                    @click="hideModal()"></div>
            </div>
        </fade-transition>
    </div>
</template>

<script lang="ts">

import { Component, Vue, Prop } from 'vue-property-decorator';
import FadeTransition from '@/components/transitions/fade-transition/fade-transition.vue';

@Component({
    name: 'modal-component',
    components: {
        fadeTransition: FadeTransition,
    },
})
export default class ModalComponent extends Vue {
    /**
     * Flag to signify if the modal is large.
     */
    @Prop({ default: false })
    private isLarge!: boolean;

    private hideModal() {
        this.$emit('hide-modal');
    }
}

</script>
<style lang="less">
@import '../../variables.less';

.repository-modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 12;
    background-color: rgba(0, 0, 0, 0.4);
    .outside-modal {
        z-index: auto;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
    }
    .repository-modal {
        z-index: 13;
        min-height: 300px;
        height: 50%;
        width: 50%;
        background: white;
        border-radius: @border-radius-standard;
        box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.5);
        position: relative;
        font-size: 1.8rem;
        &.modal-large {
            width: 65%;
            height: min-content;
            max-height: 70rem;
            margin: 6% auto;
            display: flex;
            flex-direction: column;
            min-height: 0;
            overflow: hidden;
            @media (max-width: 768px) {
                width: 95%;
            }
        }
        &.modal-small {
            width: 25%;
            height: 50%;
        }
        .repository-modal-header {
            margin-bottom: 1rem;
            padding: 3rem 3rem 1rem;
            font-family: Roboto-Medium, sans-serif;
            font-size: 2rem;
            color: #000;
            .align-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .exit-button {
                    position: absolute;
                    right: 2.3rem;
                    top: 1.6rem;
                    font-size: 3rem;
                    cursor: pointer;
                }
            }
        }
        .repository-modal-content {
            height: 100%;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            min-height: 0;
        }
        .repository-modal-content ~ .repository-modal-footer{
            height: calc(100% - 15.5rem);
            overflow: hidden;
            overflow-y: auto;
            .repository-input {
                position: relative;
                label {
                    position: absolute;
                    background: white;
                    left: 1.7rem;
                    top: -1.2rem;
                    padding: 0 1rem;
                    font-size: 1.6rem;
                    z-index: 1;
                }
            }
            .invalid-text {
                margin-top: 0.5rem;
                color: @red-darker;
            }
            input,
            select,
            textarea {
                font-size: 1.8rem;
                padding: 1rem 2.5rem;
                border-radius: @border-radius-standard;
                border: 2px solid @dark-grey;
                transition: 0.2s ease-in;
                &:focus {
                    border: 2px solid @uniper-blue;
                    transition: 0.2s ease-in;
                    outline: none;
                }
                &.invalid {
                    border: 2px solid @red-darker;
                    box-shadow: 0 1px 4px 0 rgba(255, 0, 0, 0.22);
                    transition: 0.1s ease-in;
                }
                &.full-width {
                    width: 100%;
                }
            }
            select {
                padding: 1rem 2.2rem;
                &.invalid {
                    border: 2px solid @red-darker;
                    box-shadow: 0 1px 4px 0 rgba(255, 0, 0, 0.22);
                    transition: 0.1s ease-in;
                }
            }
        }
        .repository-modal-footer {
            background: white;
            z-index: 1;
            position: absolute;
            bottom: 0;
            padding: 2rem;
            left: 0;
            right: 0;
            box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.2);
            button {
                float: right;
                box-shadow: none;
                border: none;
                font-size: 1.8rem;
                color: @warm-grey;
                transition: 0.2s ease-in;
            }
            .add-button-modal {
                font-family: Roboto-Medium;
                text-transform: uppercase;
                border-radius: 2.1rem;
                border: 1px solid @uniper-blue;
                color: @uniper-blue;
                height: 3.6rem;
                width: 25%;
                max-width: 170px;
                background: white;
                float: right;
            }
            .remove-button-modal {
                color: black;
                text-transform: uppercase;
                border-radius: 0;
                box-shadow: none;
                font-family: Roboto-Medium, sans-serif;
                float: right;
                padding-top: 0.5rem;
            }
        }
    }
}
</style>
