import { ApiNotificationResponseList, ApiResponse, Notification, NotificationFilterModel } from '@/models';
import SdkMetaDataApiHelper from '@/utils/fe-sdk/sdk-meta-data-api-helper';
import querystring from 'querystring';

export class NotificationService {
    private api = new SdkMetaDataApiHelper('notifications');
    private workflowsApi = new SdkMetaDataApiHelper('workflows');


    public async getAllNotifications(notificationFilters: NotificationFilterModel):
        Promise<ApiResponse<ApiNotificationResponseList<Notification>>> {
        const query = querystring.stringify({
            listNotifications: notificationFilters.ListNotifications ?? false,
            listTasks: notificationFilters.ListTasks ?? false,
            getRead: notificationFilters.GetRead ?? false,
            ...(notificationFilters.page) && { page: notificationFilters.page },
            ...(notificationFilters.size) && { size: notificationFilters.size },
            ...(notificationFilters.term) && { term: notificationFilters.term || '' },
            ...(notificationFilters.RelevantUseCaseIds) && { relevantUseCaseIds: notificationFilters.RelevantUseCaseIds || [] },
        });
        return (await this.api.get(query)).data as Promise<ApiResponse<ApiNotificationResponseList<Notification>>>;
    }

    public async getAllCompletedNotifications(completedNotificationFilters: any):
        Promise<ApiResponse<ApiNotificationResponseList<Notification>>> {
        const query = querystring.stringify({
            ...(completedNotificationFilters.page) && { page: completedNotificationFilters.page },
            ...(completedNotificationFilters.size) && { size: completedNotificationFilters.size },
            ...(completedNotificationFilters.term) && { term: completedNotificationFilters.term || '' },
        });
        return (await this.api.get(query, 'completed')).data as Promise<ApiResponse<ApiNotificationResponseList<Notification>>>;
    }

    public async clearAllNotifications() {
        return (await this.api.post(undefined, 'clear-all')).status;
    }

    public async clearNotificationsByList(notificationIds: number[]) {
        return (await this.api.post(notificationIds, 'clear')).status;
    }

    public async clearNotificationById(notificationId: number) {
        return (await this.api.post(undefined, `${notificationId}/clear`)).status;
    }

    public async doAction(workflowInstanceId: string, workflowActionId: number, content?: any) {
        if (!content) {
            return (await this.workflowsApi.post({ workflowActionId, content: {} },
                `${workflowInstanceId}`))
                .status;
        } else {
            return (await this.workflowsApi.post({ workflowActionId, content },
                `${workflowInstanceId}`,
                )).status;
        }
    }

    public async markNotificationAsRead(notificationId: number) {
        return (await this.api.post(undefined, `${notificationId}/mark-as-read`)).status;
    }

    public async markNotificationsAsRead(notificationIds: number[]) {
        return (await this.api.post(notificationIds, 'mark-as-read')).status;
    }
}
