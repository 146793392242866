<template>
    <div class="snackbar" :class="{'show': isVisible}">
        <span v-if="message !== ''">{{$t(message)}}</span>
        <button type="button" @click="dismiss()">{{$t('dismiss')}}</button>
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utils/eventbus';
import './snackbar.less';

@Component({
    name: 'snackbar',
})
export default class Snackbar extends Vue {
    private message = '';
    private duration = 5000;
    private isVisible = false;
    private timeout!: ReturnType<typeof setTimeout>;

    private created() {
        EventBus.$on(EventBus.GLOBAL.SHOW_SNACKBAR, (message: string, overrideDefaultDuration: number | null) => {
            if (overrideDefaultDuration) {
                this.duration = overrideDefaultDuration;
            }
            this.message = message;
            this.isVisible = true;
            this.timeout = setTimeout(() => {
                this.isVisible = false;
            }, this.duration);
        });
    }

    private dismiss(): void {
        this.isVisible = false;
        clearTimeout(this.timeout);
    }
}

</script>

<style lang='less' scoped>
@import '../../variables.less';

.snackbar {
    position: fixed;
    left: 1%;
    border: 1px solid @dark-grey;
    border-radius: 5px;
    background: @dark-grey;
    color: @white;
    font-family: Roboto, sans-serif;
    font-size: 1.2rem;
    padding: 1rem;
    box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);
    bottom: -100px;
    transition: all 0.2s ease-in;
    z-index: 15;
    &.show {
        transition: all 0.2s ease-in;
        bottom: 2%;
    }
    button {
        color: @medium-violet;
        background: transparent;
        border: none;
        outline: none;
        font-size: 1.6rem;
        margin-left: 1rem;
        text-transform: uppercase;
        box-shadow: none;
        &:focus {
            outline: none;
        }
        &:hover {
            cursor: pointer;
        }
    }
}
</style>