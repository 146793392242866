import { RelatedIdea } from '@/models/related-idea';
import SdkMetaDataApiHelper from '@/utils/fe-sdk/sdk-meta-data-api-helper';

export class RelatedUseCaseService {
    private api = new SdkMetaDataApiHelper('use-case');

    public async getRelatedIdeasOfUseCase(useCaseId: number): Promise<RelatedIdea[]> {
        const response: any = await this.api.getByPathOrParam(`${useCaseId}/ideas`)
        return response.data.result.useCases;
    }
}
