import { ApiResponse, ApiResponseList, UseCase } from '@/models';
import SdkMetaDataApiHelper from '@/utils/fe-sdk/sdk-meta-data-api-helper';
import querystring from 'querystring';

export class UseCaseService {
    private api = new SdkMetaDataApiHelper('use-cases');


    public async getUseCasesSuggestions(searchTerm?: string,
        ): Promise<ApiResponse<ApiResponseList<UseCase>>> {
        const query = querystring.stringify({searchTerm});
        return (
            await (this.api.get(query, `suggestions`)
        )).data as Promise<ApiResponse<ApiResponseList<UseCase>>>;
    }

    public async getAllUseCases(
        searchTerm?: string,
        categoryFilter?: number[],
        technologyFilter?: number[],
        statusFilter?: number[],
        applicationTypeFilter?: number[]): Promise<ApiResponse<ApiResponseList<UseCase>>> {
        const query = querystring.stringify({
            ...(searchTerm) && { SearchTerm: searchTerm },
            ...(categoryFilter?.length) && { CategoryFilter: categoryFilter },
            ...(technologyFilter?.length) && { TechnologyFilter: technologyFilter },
            ...(statusFilter?.length) && { StatusFilter: statusFilter },
            ...(applicationTypeFilter?.length) && { ApplicationTypeFilter: applicationTypeFilter },
        });
        return (await this.api.get(query, 'search')).data as Promise<ApiResponse<ApiResponseList<UseCase>>>;
    }
}
