<template>
    <div>
        <div class="search-result-box mt-2" v-if="searchItems">
            <div
                class="search-result-item"
                v-for="(useCase, useCaseIndex) of searchItems"
                :key="useCaseIndex">
                <div v-pui-form-grid-row >
                  <div class="flex-row flex-center pui-form-grid-column-2 app-action" @click="redirectTo(useCase, useCaseIndex)">
                    <div class="search-result-image">
                      <img :src="useCase.logoUrl" alt="" class="logo-app" />
                    </div>
                    <div class="search-result-title">{{useCase.name}}</div>
                  </div>
                  <div class="flex-row flex-center pui-form-grid-column-10">{{ getPlainTextDescription(useCase.shortDescription) }}</div>
                </div>
            </div>
        </div>
        <pui-loader-error
            v-if="!searchItems.length && !$store.state.isLoading"
            :title="$t('noSearchResultsTitle')"
            :message="$t('noSearchResultsMessage')"
            icon="error-empty-data"
        />
        <app-details-modal
            v-if="showModal"
            :selected-use-case="selectedUseCase"
            @cancel="toggleModal()"/>
    </div>
</template>

<script lang='ts'>
import { Component, Vue, Prop } from 'vue-property-decorator';
import AppDetailsModal from '@/components/app-details-modal/app-details-modal.vue';
import { UseCase } from '@/models';
import RichTextFormatter from '@/utils/rich-text-formatter';

@Component({
    name: 'search-result',
    components: {
        appDetailsModal: AppDetailsModal,
    },
})
export default class SearchResult extends Vue {

    @Prop()
    private searchItems!: UseCase;

    private showModal = false;
    private selectedUseCase: UseCase | null = null;

    private redirectTo(useCase: UseCase) {
        switch ( useCase.url ) {
            case 'digital-transformation':
                this.$router.push({ path: `/` + useCase.url });
                break;
            case 'underConstruction':
                alert('Under construction');
                break;
            default:
                this.$emit('open-details-modal', useCase);
        }
    }

    private toggleModal(useCase?: UseCase) {
        this.showModal = !this.showModal;
        if (useCase) {
            this.selectedUseCase = useCase;
        }
    }

    private getPlainTextDescription(text: string): string {
      return RichTextFormatter.formatText(text);
    }
}
</script>

<style lang="less">
@import '../../../variables.less';

.search-result {
    &-box {
        display: flex;
        flex-direction: column;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    &-header {
        color: @dark-grey;
        background-color: @light-grey;
        padding: 1.5rem;
        font-size: 1.4rem;
        font-weight: bold;
        border-bottom: 0.1rem solid @grey-lighter;
    }

    &-item {
        display: flex;
        align-items: center;
        padding: 2rem 2.5rem;
        background: @white;
        border-bottom: 0.1rem solid @grey-lighter;
        .app-action {
          cursor: pointer;
        }
    }

    &-image {
        width: 4.2rem;
        height: 4.2rem;
    }

    &-title {
        display: flex;
        align-items: center;
        color: @uniper-blue;
        font-size: 1.4rem;
        font-weight: bold;
        margin-left: 1.5rem;
    }
}

.logo-app {
  width: 4.2rem;
  height: 4.2rem;
}
</style>
