<template>
    <div>
        <!--TODO export to filter component id necessary -->
        <div class="app-container-digital-transformation">
          <div class="app-tree-structure-container">
              <div class="box-header">Menu</div>
          </div>
          <div class="app-details">
            <div class="box-header">Data</div>
            <div id="container" class="app-details-data">
				<h1 id="master-data">Master Data Management</h1>
				<p>MDM stands for Maser Data Management. This module will enable management of Master Data in ADAM platform.<p>
				<ul id="tbd">TBD</ul>
				<li>Portfolios not existing (Nordic nuclear and hydro)</li>
				<li>Time zone for country/portfolio instead power plant (or both).</li>
				<li>States and regions has no link to country. To be added?</li>
				<li>ENUM_KKS_SID to be ignored for the moment (object master). Postponed.</li>
				<li>For unit is missing the SAP Structure Standard (KKS, AKZ, TriGram, etc)</li>
				<li>For each unit will be a list of WorkCenters available (from the big list)</li>
				<li>For each WorkCenter will be a list of SAP Structure equipment identifier (KKS, AKZ, etc. code)</li>
				<li>Unit River many to many relationship. No management of rivers available. Postponed.</li>
				<li>Only 2 energy types for a unit. What if more energy types required (e.g. hydrogen). I propose 1..n energy types</li>
				<li>To be introduced a new role to add output streams and manage available assets they have access to. Or add it to ADAM Platform Repository.</li>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<h1 id="actors-0">Actors</h1>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<h4>MDM module will be access by users having the following permissions:</h4>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<li>“MDM admin”. Users with this permission will be able to adjust Master Data details</li>
				<li>“MDM reader”. Users with this permission will be able to navigate and see Master Data details. </li>
				<p>Additionally MDM can be restricted at client level (e.g. view master data of a single client only).</p>
				<h1 id="general-screens">General reusable screens</h1>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<p>These are screens that handles multiple types of data with same structure and functionality.</p>
				<h2 id="mdm-1">UC MDM 1.1: View EnumList_Master list</h2>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<h3 id="actors-master-list">Actors</h3>
				<h3>Users with following roles can access this page:</h3>
				<li id="mdm-1-admin">MDM admin</li>
				<li>MDM reader</li>
				<h3 id="mdm-1-preconditions">Preconditions</h3>
				<li>User is logged in and has access to MDM module</li>
				<h3 id="mdm-1-description">Description</h3>
				<li>An ID_TYPE_SID is provided</li>
				<h3 id="mdm-1-post">Postconditions</h3>
				<li>None</li>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<h2 id="mdm-2">UC MDM 1.2: Manage EnumList_Master list</h2>  
				<h3 id="mdm-2-actors">Actors</h3>
				<h3>Users with following roles can access this page:</h3>
				<li id="mdm-2-admin">MDM admin</li>
				<h3 id="mdm-2-preconditions">Preconditions</h3>
				<li>User is logged in and has access to MDM module</li>
				<h3 id="mdm-2-description">Description</h3>
				<li>An ID_TYPE_SID is provided</li>
				<h3 id="mdm-2-post">Postconditions</h3>
				<li>None</li>

				<h1 id="clients">Clients</h1>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<div id="mdm-3">UC MDM: View list of clients (View EnumList_Master screen)</div>
				<h3 id="mdm-3-actors">Actors</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<h3>Users with following roles can access this page:</h3>
				<li id="mdm-3-admin">MDM admin</li>
				<li>MDM reader</li>
				<h3 id="mdm-3-preconditions">Preconditions</h3>
				<li>User is logged in and has access to MDM module</li>
				<h3 id="mdm-3-description">Description</h3>
				<li>An ID_TYPE_SID is provided</li>
				<h3 id="mdm-3-post">Postconditions</h3>
				<li>None</li>
				<h2 id="mdm-4">UC MDM: Manage the list of Clients (Manage EnumList_Master screen)</h2>  
				<h3 id="mdm-4-actors">Actors</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<h3>Users with following roles can access this page:</h3>
				<li id="mdm-4-admin">MDM admin</li>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<h3 id="mdm-4-preconditions">Preconditions</h3>
				<li>User is logged in and has access to MDM module</li>
				<h3 id="mdm-4-description">Description</h3>
				<li>An ID_TYPE_SID is provided</li>
				<h3 id="mdm-4-post">Postconditions</h3>
				<li>None</li>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus 
					integer feugiat scelerisque varius morbi. Lectus urna duis convallis convallis tellus. Condimentum id venenatis a condimentum vitae sapien. Nisl tincidunt eget nullam non nisi est sit amet. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus. 
					Cras pulvinar mattis nunc sed blandit libero volutpat. Iaculis urna id volutpat lacus laoreet non. Bibendum est ultricies integer quis auctor elit sed. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis auctor elit sed vulputate mi. 
					Malesuada nunc vel risus commodo viverra maecenas accumsan lacus vel. Id semper risus in hendrerit gravida. Felis eget nunc lobortis mattis aliquam. At lectus urna duis convallis convallis tellus id interdum velit. Quis eleifend quam adipiscing vitae proin. Egestas purus 
					viverra accumsan in nisl nisi scelerisque eu. Ultricies leo integer malesuada nunc vel risus commodo viverra
				</p>
            </div>
          </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header/header.vue';

interface Node {
    name: string;
    id: string;
    children: Node[];
}

@Component({
    name: 'digital-transformation',
    components: {
        uiHeader: Header,
    },
})
export default class DigitalTransformationPage extends Vue {
    private treeData = [
        {
            name: 'Master Data Management',
            id: 'master-data',
            children: [
                {
                name: 'TBD',
                id: 'tbd',
                },
            ],
        },
        {
            name: 'Actors',
            id: 'actors-0',
            children: [],
        },
        {
            name: 'General reusable screens',
            id: 'general-screens',
            children: [
                {
                    name: 'UC MDM 1.1: View EnumList_Master list ',
                    id: 'mdm-1',
                    children: [
                        {
                            name: 'Actors',
                            id: 'actors-master-list',
                        },
                        {
                            name: 'MDM admin',
                            id: 'mdm-1-admin',
                        },
                        {
                            name: 'Preconditions',
                            id: 'mdm-1-preconditions',
                        },
                        {
                            name: 'Description',
                            id: 'mdm-1-description',
                        },
                        {
                            name: 'Postconditions',
                            id: 'mdm-1-post',
                        },
                    ],
                },
                {
                    name: 'UC MDM 1.2: Manage EnumList_Master list ',
                    id: 'mdm-2',
                    children: [
                        {
                            name: 'Actors',
                            id: 'mdm-2-actors',
                        },
                        {
                            name: 'MDM admin',
                            id: 'mdm-2-admin',
                        },
                        {
                            name: 'Preconditions',
                            id: 'mdm-2-preconditions',
                        },
                        {
                            name: 'Description',
                            id: 'mdm-2-description',
                        },
                        {
                            name: 'Postconditions',
                            id: 'mdm-2-post',
                        },
                    ],
                },
            ],
        },
        {
            name: 'Clients',
            id: 'clients',
            children: [
                {
                    name: 'UC MDM: View list of clients (View EnumList_Master screen)',
                    id: 'mdm-3',
                    children: [
                        {
                            name: 'Actors',
                            id: 'mdm-3-actors',
                        },
                        {
                            name: 'MDM admin',
                            id: 'mdm-3-admin',
                        },
                        {
                            name: 'Preconditions',
                            id: 'mdm-3-preconditions',
                        },
                        {
                            name: 'Description',
                            id: 'mdm-3-description',
                        },
                        {
                            name: 'Postconditions',
                            id: 'mdm-3-post',
                        },
                    ],
                },
                {
                    name: 'UC MDM: Manage the list of Clients (Manage EnumList_Master screen)',
                    id: 'mdm-4',
                    children: [
                        {
                            name: 'Actors',
                            id: 'mdm-4-actors',
                        },
                        {
                            name: 'MDM admin',
                            id: 'mdm-4-admin',
                        },
                        {
                            name: 'Preconditions',
                            id: 'mdm-4-preconditions',
                        },
                        {
                            name: 'Description',
                            id: 'mdm-4-description',
                        },
                        {
                            name: 'Postconditions',
                            id: 'mdm-4-post',
                        },
                    ],
                },
            ],
        },
    ];
    private nodeSelected(node: Node): void {
        if (node && node.id) {
            const element = document.getElementById(`${node.id}`);
            if (element) {
                element.scrollIntoView({behavior: 'smooth'});
            }
        }
    }
}
</script>

<style lang='less'>
@import '../../variables.less';
.app-container-digital-transformation {
	display: flex;
	font-family: Roboto, sans-serif;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    padding-top: 2%;
	.app-tree-structure-container {
		height: 100%;
		flex: 0 0 25%;
		border: 1px solid #e5e9ec;
		margin: 1em;
		.adam-tree {
		font-size: large;
		padding-right: 1rem;
		}
	}
	.app-details {
		flex: 0 0 68%;
		border: 1px solid #e5e9ec;
		margin: 1em;
	}
	.app-details-data {
		padding: 1em;
		overflow-y: auto;
		max-height: 75vh;
		position: relative;
		scroll-behavior: smooth;
	}
}
.box-header {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	border-bottom-left-radius: 0rem;
	border-bottom-right-radius: 0rem;
	border-left: 0;
	border-right: 0;
	background-color: @background-grey;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
	padding: 10px; 
	font-size: large;
}
</style>
