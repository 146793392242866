<template>
    <transition-group name="list">
        <slot/>
    </transition-group>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class VerticalListTransition extends Vue {
    constructor() {
        super();
    }
}
</script>
<style lang="less">
.list-enter-active, .list-leave-active {
    transition: all 0.3s;
}
.list-enter, .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>