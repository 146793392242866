import Vue from 'vue';

export const EventBus = new Vue({
    data() {
        return {
            GLOBAL: {
                SHOW_SNACKBAR: 'global.show-snackbar',
                SHOW_TOAST_MESSAGE: 'global.show-toast-message',
            },
            NOTIFICATIONS: {
                RELOAD_HEADER_NOTIFICATIONS: 'notifications.reload-header-notifications',
                RELOAD_SIMPLE_NOTIFICATIONS: 'notifications.reload-simple-notifications',
            },
        };
    },
});
