<template>
  <div class="flex-row flex-start use-case-wrapper">
    <div
      class="app-card-item"
    >
      <div class="app-card-image">
        <div class="wrapper-status">
          <div
            v-if="shouldShowBadgeOnUseCase()"
            :class="['app-card-badge', getUseCaseStatusStyle()]"
          >
            {{ getUseCaseBadgeText() }}
          </div>
          <img :src="useCase.logoUrl" alt="" class="logo-app" />
        </div>
      </div>
    </div>
    <div class="flex-col app-details">
      <span class="app-name">{{ getUseCaseName() }}</span>
      <span class="app-description">{{ getUseCaseDescription() }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { StatusEnum, UseCase } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Statuses } from '@/models/status-enum';
import RichTextFormatter from '@/utils/rich-text-formatter';
import { ApplicationType } from '@/models/usecase';

@Component({
  name: 'app-card',
})
export default class AppCard extends Vue {
  @Prop({required: true})
  private useCase!: UseCase;

    private statusModules = {
        color: {
            [StatusEnum.New]: 'new',
            [StatusEnum.Updated]: 'updated',
            [StatusEnum.Soon]: 'soon',
        },
        name: {
            [StatusEnum.New]: this.$t('appStatuses.new'),
            [StatusEnum.Updated]: this.$t('appStatuses.updated'),
            [StatusEnum.Soon]: this.$t('appStatuses.soon'),
        },
    };

  private getUseCaseBadgeText() {
    if (this.useCase.applicationType === ApplicationType[ApplicationType.ReportApplication]) {
      return this.$t('reportBadge');
    }

    const statusNames: unknown = this.statusModules.name;
    return (statusNames as Statuses)[StatusEnum[this.useCase.status]];
  }

  private getUseCaseStatusStyle() {
    if (this.useCase.applicationType === ApplicationType[ApplicationType.ReportApplication]) {
      return 'app-card-badge__report';
    }

    const statusNames: unknown = this.statusModules.color;
    return (statusNames as Statuses)[StatusEnum[this.useCase.status]];
  }

  private getUseCaseName(): string {
    return this.useCase.name;
  }

  private shouldShowBadgeOnUseCase(): boolean {
    return !(this.hasStatusNone && !this.isAppType(ApplicationType.ReportApplication));
  }

  private getUseCaseDescription(): string {
    if (this.useCase.shortDescription) {
      return this.removeTags(this.useCase.shortDescription);
    }
    return '';
  }

  private removeTags(text: string) {
    return RichTextFormatter.formatText(text);
  }

  private get hasStatusNone(): boolean {
    if (!this.useCase.status) {
      return false;
    }

    return this.useCase.status.toString() === StatusEnum.None.toString();
  }

  private isAppType(type: ApplicationType): boolean {
    return this.useCase.applicationType === ApplicationType[type];
  }
}
</script>

<style lang="less" scoped>
@import '../../variables.less';

.app-card-item {
  padding-bottom: 1.2rem;
  display: flex;
  margin-right: 2.4rem;

  .app-card-badge {
    position: absolute;
    color: white;
    font-size: 1.2rem;
    border-radius: 0.64rem;
    font-weight: bold;
    padding: 0.16rem 0.64rem;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &__report {
      right: 0;

      color: @white;
      background-color: @dark-grey-lighter;
    }
  }

  .app-card-image {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    img {
      border-radius: 2.16rem;
    }

    .wrapper-status {
      position: relative;

      img {
        cursor: pointer;
      }
    }
  }
}

.app-details {
  line-height: 2.5rem;
  max-width: 43rem;

  .app-name {
    font-weight: bold;
  }

  .app-description {
    word-break: break-word;
  }
}

.new {
  background-color: @lighter-blue;
}

.updated {
  background-color: @contextual-red;
}

.soon {
  background-color: @darker-green;
}
</style>
