<template>
    <div
        v-if="application"
        class="incident-page-container"
    >
        <div
            v-if="!hasRequestError && application.isServiceNowConfigured"
            class="flex-col"
        >
            <pui-headline type="h2">{{ $t('reportIncident') }}</pui-headline>
            <pui-collapsible
                class="mt-2 pb-2 collapsible"
                :headline="$t('incidentForm.collapsibleHeadline')"
                :expandable="false"
                open
            >
                <pui-form
                    @submit.prevent="submitForm()"
                >
                    <div class="text-section">
                        <pui-headline type="h4" class="text-bold">
                            {{ $t('incidentForm.collapsibleTextTitle') }}
                        </pui-headline>
                        <p>
                            {{ $t('incidentForm.collapsibleTextBody') }}
                        </p>
                    </div>
                    <div v-pui-form-grid-row>
                        <pui-form-group
                            :label="$t('incidentForm.useCase.name')"
                            v-pui-form-grid-column="12"
                        >
                            <pui-form-input-field
                                is-disabled
                                :value="application.name"
                            />
                        </pui-form-group>
                    </div>
                    <div v-pui-form-grid-row>
                        <pui-form-group
                            :label="$t('incidentForm.title.name')"
                            label-for="title"
                            :is-valid="!incidentForm.title.isInvalid"
                            show-required-label
                            v-pui-form-grid-column="12"
                        >
                            <pui-form-input-field
                                id="title"
                                v-model="incidentForm.title.value"
                                :placeholder-text="$t('incidentForm.title.name')"
                                :is-valid="!incidentForm.title.isInvalid"
                                :is-disabled="isInputDisabled"
                                is-required
                                autofocus
                            />
                            <template #error-message>
                                {{ incidentForm.title.error }}
                            </template>
                        </pui-form-group>
                    </div>
                    <div v-pui-form-grid-row>
                        <pui-form-group
                            :label="$t('incidentForm.body.name')"
                            label-for="body"
                            show-required-label
                            :is-valid="!incidentForm.body.isInvalid"
                            v-pui-form-grid-column="12"
                        >
                            <pui-form-textarea
                                id="body"
                                v-model="incidentForm.body.value"
                                :is-valid="!incidentForm.body.isInvalid"
                                :is-disabled="isInputDisabled"
                                :rows="6"
                                grow
                                is-required
                            />
                            <template #error-message>
                                {{ incidentForm.body.error }}
                            </template>
                        </pui-form-group>
                    </div>
                    <div v-pui-form-grid-row>
                        <pui-form-group
                            :label="$t('incidentForm.file.name')"
                            label-for="file"
                            :is-valid="!incidentForm.file.isInvalid"
                            v-pui-form-grid-column="12"
                        >
                            <pui-form-file
                                id="file"
                                :label="$t('incidentForm.file.buttonCta')"
                                :disabled="isInputDisabled"
                                @puiFormFile:added="onFileAdded"
                                @puiFormFile:removed="onFileRemoved"
                            />
                            <template #error-message>
                                {{ incidentForm.file.error }}
                            </template>
                        </pui-form-group>
                    </div>
                    <div
                        class="flex-row justify-end items-center mt-2"
                        v-pui-form-grid-row
                    >
                        <pui-button
                            :disabled="hasCreatedIncident"
                        >
                            {{ $t('incidentForm.createCta') }}
                        </pui-button>
                    </div>
                </pui-form>
            </pui-collapsible>
        </div>
        <pui-loader-error
            v-if="hasRequestError"
            :title="$t('errorRequestTitle')"
            :message="$t('errorRequestMessage')"
            icon="error-not-available"
            :global="true"
            :buttons="[{ small: 'true', label: $t('tryAgain'), onClick: () => this.hasRequestError = false }]"
        />
        <pui-loader-error
            v-if="!application.isServiceNowConfigured"
            :title="$t('incidentForm.notConfigured.title')"
            :message="$t('incidentForm.notConfigured.message')"
            :global="true"
            :buttons="[{ small: 'true', label: $t('goBack'), onClick: () => $router.back() }]"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ModuleApplicationService } from '@/services';
import { FieldForm, Form, UseCase } from '@/models';
import { ApplicationIncidentForm } from '@/models/form';
import MessageHandler from '@/utils/message-handler';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';

@Component({
    name: 'incident-page',
})
export default class IncidentPage extends Vue {
    private readonly MAX_FILE_SIZE = 4 * 1000 * 1000;

    @Prop()
    private useCaseId!: string;

    private readonly applicationService = new ModuleApplicationService();
    private hasRequestError = false;
    private hasCreatedIncident = false;

    private uploadedFile: File | null = null;

    private incidentForm: Form = {
        title: {
            value: '',
            type: 'title',
            isInvalid: false,
            error: '',
        },
        body: {
            value: '',
            type: 'message',
            isInvalid: false,
            error: '',
        },
        file: {
            value: '',
            type: 'file',
            isInvalid: false,
            error: '',
        },
    };

    private get application(): UseCase | undefined {
        return this.$store.getters.applications
            .find((useCase: UseCase) => useCase.id === parseInt(this.useCaseId, 10));
    }

    private get isLoading(): boolean {
        return this.$store.getters.isLoading;
    }

    private get isInputDisabled(): boolean {
        return this.isLoading || this.hasCreatedIncident;
    }

    private checkInput(field: FieldForm): void {
        field.isInvalid = false;
        field.error = '';

        switch (field.type) {
            case 'title':
                this.validateTitle(field);
                break;
            case 'message':
                this.validateMessage(field);
                break;
            case 'file':
                this.validateFile(field);
        }
    }

    private async onFileAdded(payload: File[]): Promise<void> {
        if (payload.length === 0) {
            return;
        }

        this.uploadedFile = payload[0];
    }

    private onFileRemoved(): void {
        this.uploadedFile = null;

        this.checkInput(this.incidentForm.file);
    }

    private validateTitle(titleField: FieldForm): void {
        if (titleField.value === '') {
            titleField.isInvalid = true;
            titleField.error = this.$t('incidentForm.title.requiredError') as string;
            return;
        }

        if (titleField.value.length >= 160) {
            titleField.isInvalid = true;
            titleField.error = this.$t('incidentForm.title.tooLongError') as string;
        }
    }

    private validateMessage(messageField: FieldForm): void {
        if (messageField.value === '') {
            messageField.isInvalid = true;
            messageField.error = this.$t('incidentForm.body.requiredError') as string;
        }
    }

    private validateFile(fileField: FieldForm): void {
        if (!this.uploadedFile) {
            return;
        }

        if (this.uploadedFile.size > this.MAX_FILE_SIZE) {
            fileField.isInvalid = true;
            fileField.error = this.$t('incidentForm.file.errors.sizeExceeded') as string;
            return;
        }
    }

    private async submitForm(): Promise<void> {
        this.$store.commit('loading', true);

        let isValid = true;
        Object.values(this.incidentForm).forEach((field: FieldForm) => {
            this.checkInput(field);
            if (field.isInvalid) {
                isValid = false;
            }
        });

        if (isValid) {
            const incident: ApplicationIncidentForm = {
                useCaseId: parseInt(this.useCaseId, 10),
                title: this.incidentForm.title.value,
                body: this.incidentForm.body.value,
            };

            try {
                const response = await this.applicationService.createIncident(incident);

                if (this.uploadedFile) {
                    await this.uploadAttachment(response.result.id);
                }

                this.hasCreatedIncident = true;
                MessageHandler.showToastMessage(
                  this.$t('incidentForm.successfulSubmit') as string,
                  ToastMessageTypes.SUCCESS
                );
            } catch (err) {
                this.hasRequestError = true;
            }
        }

        this.$store.commit('loading', false);
    }

    private async uploadAttachment(incidentId: string): Promise<void> {
        if (!this.uploadedFile) {
            return;
        }

        try {
            await this.applicationService.uploadAttachment(incidentId, this.uploadedFile);
        } catch (err) {
            this.hasRequestError = true;
        }
    }
}
</script>

<style scoped lang="less">
@import '../../variables.less';

.incident-page-container {
    max-width: 125rem;
    margin: 3rem auto;

    /deep/ input {
        padding: 0;
    }

    /deep/ .pui-form-file__files-wrapper {
        align-items: flex-start;
    }

    /deep/ .pui-form-file > .pui-button {
        margin-bottom: 1rem;
    }

    .pui-form-file {
      padding-left: 0;
    }

    .collapsible {
        background-color: white;
    }

    .items-center {
        align-items: center;
    }

    .text-muted {
        color: @dark-grey-lighter;
    }

    .text-section {
        padding-top: 2rem;
        padding-left: 2.5rem;
    }

    .text-bold {
        font-weight: bold;
    }
}
</style>
