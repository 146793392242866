import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { store } from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import PebbleUI from '@enerlytics/pebble-ui/dist/pebble-ui';
import 'adam.ui-core/dist/umd';
import 'adam.ui-core/utils/scaling.css';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import VueI18n from 'vue-i18n';
import sdk from './utils/fe-sdk/sdk';

Vue.config.productionTip = false;
Vue.use(VueI18n);

Vue.use(PebbleUI, {
    dateTimeLocale: 'en-US',
    dateTimeTimeZone: 'Europe/London',
});

Vue.component('font-awesome-icon', FontAwesomeIcon);

// eslint-disable-next-line
// @ts-ignore
library.add(faEnvelope, faPhoneAlt, faAngleLeft, faAngleRight);

const lang = 'en-GB';
const i18n = new VueI18n({
    locale: lang,
    messages: require(`./locales/${lang}.json`),
});

sdk?.auth.init().then(() => {
    new Vue({
        i18n,
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app');
})

