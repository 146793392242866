<template>
    <div class="header-search-container">
        <!-- <pui-form-input-field
            :placeholder-text="$t('search')"
            :trailing-icon-name="searchIcon"
            :trailing-icon-is-clickable="true"
            :is-readonly="false"
            :is-disabled="false"
            @trailing-icon:click="emitSearchItem()"
            v-model="searchItem"
        /> -->
        <!-- The below to be removed and refactored in the next ticket - let it commented for now -->

        <pui-form-type-ahead
            v-model="selectedSuggestion"
            :isLoading="suggestionsLoading"
            :options="suggestionsList"
            :max-visible-options="6"
            search-input-id="searchTerm"
            name="searchTerm"
            :search-input-placeholder="inputPlaceholder"
            :search-input-min-length="2"
            :label="$t('search')"
            :use-local-cache="false"
            @search="onSearch"
            @change="onChange"
            @keyup.enter.native="gotoSearch"
            @keyup.native="searchInputTextChanged"
                    >
            <template slot-scope="slotProps">
                <pui-list-option
                    icon-name="circle-input"
                    :label="slotProps.option.label"
                    :secondary-label="slotProps.option.secondaryLabel"
                />
            </template>
        </pui-form-type-ahead>
        <div
          class="clear-button"
          v-on:click="clearSearch"
          v-if="shouldShowClearButton"
        >
            <pui-icon
                icon-name="dismiss"
                size="16"
            />
        </div>
        <div
          :class="['search-icon', temporarySearchItem !== '' ? 'search-icon_active' : ''].join(' ')"
          v-on:click="gotoSearch()"
        >
          <pui-icon
            icon-name="search"
          />
        </div>
        <span
          v-if="shouldShowInfoMessage"
          class="ml-1 info-message">
          {{$t('searchTerm')}}"{{searchItem}}"
        </span>
    </div>
</template>

<script lang='ts'>
import { Component, Vue, Watch } from 'vue-property-decorator';
import { SearchResult } from '@/models';

@Component({
    name: 'header-search',
})
export default class HeaderSearch extends Vue {
    private searchItem = '';
    private temporarySearchItem = '';

    private selectedSuggestion = [];

    private searchActive = true;
    private searchIcon = 'search';
    private showSearchTermInfo = false;

    @Watch('$route.query.q')
    private onSearchQuery(q: string) {
        this.searchItem = q;
        this.temporarySearchItem = q;
        this.selectedSuggestion = [];
    }

    private mounted(): void {
      this.$root.$on('clearSearchFilters', () => {
        this.temporarySearchItem = '';
      });
    }

    private get suggestionsLoading() {
        return this.$store.getters.isLoadingSuggestions;
    }

    private get suggestionsList() {
        return this.$store.getters.searchSuggestions;
    }

    private get shouldShowInfoMessage() {
      if (
          this.showSearchTermInfo &&
          this.selectedSuggestion.length === 0 &&
          this.searchItem && this.searchItem.length > 0
        ) {
        return true;
      }

      return false;
    }

    private get shouldShowClearButton() {
      if (this.temporarySearchItem?.length > 0 || this.searchItem?.length > 0 || this.selectedSuggestion?.length > 0) {
        return true;
      }
      return false;
    }

    private emitSearchItem() {
        if (this.searchItem === '') {
            return;
        }
        this.searchActive = !this.searchActive;
        if (this.searchActive) {
            this.searchIcon = 'search';
            this.searchItem = '';
            this.$emit('search-item', this.searchItem);
        } else {
            this.searchIcon = 'close';
            this.$emit('search-item', this.searchItem);
        }

    }

    private clearSearch() {
      this.searchItem = '';
      this.temporarySearchItem = '';
      this.selectedSuggestion = [];
      this.$router.push({name : 'MainPage'});
    }

    private onSearch(searchTerm: string) {
        this.$emit('type-to-search', searchTerm);
    }

    private gotoSearch(e: Event) {
        const searchInput = document.getElementById('searchTerm');

        if (e) {
          this.searchItem = (searchInput as HTMLInputElement).value;
        } else {
          this.searchItem = this.temporarySearchItem;
        }

        this.$emit('search-item', this.searchItem);

        // hide suggestions
        if (searchInput) {
            searchInput.blur();
        }

        if (this.searchItem !== this.$route.query.q) {
            this.$router.push({
                name: 'Search',
                query: {
                    q: this.searchItem,
                },
            });
        }
    }

    private searchInputTextChanged() {
        const searchInput = document.getElementById('searchTerm');

        this.temporarySearchItem = (searchInput as HTMLInputElement).value;
    }

    private onChange(item: SearchResult[]) {
        if (item && item.length && item[0]) {
          if (typeof item[0].coodeId !== 'undefined') {
            this.$emit('goto-result', item[0]);
            this.selectedSuggestion = [];
          } else {
            this.searchItem = item[0]['name'];
            this.temporarySearchItem = this.searchItem;
          }
        }
    }

    get inputPlaceholder(): string {
      if (this.temporarySearchItem !== '') {
        return this.temporarySearchItem;
      }
      return this.$t('searchPlaceholder') as string;
    }
}
</script>

<style scoped lang='less'>
@import '../../variables.less';

.header-search-container {
  font-family: Roboto, sans-serif;
  font-size: 1.6rem;
  position: relative;
  width: 78rem;

    /deep/ #searchTerm {
        padding: 0;
        &::placeholder               { color: @warm-grey; }
        &::-webkit-input-placeholder { color: @warm-grey; }
        &:-moz-placeholder           { color: @warm-grey; }
        &::-moz-placeholder          { color: @warm-grey; }
        &:-ms-input-placeholder      { color: @warm-grey; }
    }

    .search-icon {
        color: black;
        position: absolute;
        top: 0;
        right: 0;
        width: 4rem;
        height: 4rem;
        border-radius: 0 0.32rem 0.32rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            margin: auto;
            height: 40%;
        }

        &_active {
          background-color: @dark-blue;
          color: white;
        }
    }

    .clear-button {
        position: absolute;
        top: 1.2rem;
        right: 5.2rem;
        width: 1.7rem;
        height: 1.7rem;
        border-radius: 1rem;
        display: flex;
        cursor: pointer;
    }

    .info-message {
      font-family: Roboto, sans-serif;
      font-style: italic;
      font-size: 1.4rem;
    }
}
</style>
