import { notifications } from './modules/notifications';
import Vue from 'vue';
import Vuex from 'vuex';
import { UseCase, Application, IdeasSuggestion, SearchResult, Category, Technology, UserGroup } from '@/models';
import { ApplicationType } from '@/models/usecase';
Vue.use(Vuex);

const applicationState: {
    isLoading: boolean;
    isLoadingSuggestions: boolean[];
    useCases: UseCase[] | null;
    ideas: IdeasSuggestion[] | null;
    reports: UseCase[] | null;
    applications: Application[] | null;
    searchUseCasesSuggestions: UseCase[] | null;
    searchIdeasSuggestions: IdeasSuggestion[] | null;
    tabs: [] | null;
    categories: Category[] | null;
    technologies: Technology[] | null;
    user: unknown | null;
    userGroups: UserGroup[];
} = {
    isLoading: false,
    isLoadingSuggestions: [],
    useCases: [],
    ideas: [],
    reports: [],
    applications: [],
    searchUseCasesSuggestions: [],
    searchIdeasSuggestions: [],
    tabs: [],
    categories: [],
    technologies: [],
    user: {},
    userGroups: [],
};

export const store = new Vuex.Store({
    state: applicationState,
    mutations: {
        loading(state, isLoading) {
            state.isLoading = isLoading;
        },
        loadingSuggestions(state, flag) {
            if (flag) {
                state.isLoadingSuggestions.push(true);
            } else {
                state.isLoadingSuggestions.pop();
            }
        },
        useCases(state, useCases) {
            useCases = useCases.sort((a: UseCase, b: UseCase) => a.name > b.name ? 1 : -1);
            state.useCases = useCases;
        },
        ideas(state, ideas) {
            ideas = ideas.sort((a: IdeasSuggestion, b: IdeasSuggestion) => a.title > b.title ? 1 : -1);
            state.ideas = ideas;
        },
        reports(state, reports) {
            reports = reports.sort((a: UseCase, b: UseCase) => a.name > b.name ? 1 : -1);
            state.reports = reports;
        },
        applications(state, applications) {
            applications = applications.sort((a: UseCase, b: UseCase) => a.name > b.name ? 1 : -1);
            state.applications = applications;
        },
        clearSearchSuggestions(state) {
            state.searchUseCasesSuggestions = [];
            state.searchIdeasSuggestions = [];
        },
        searchUseCasesSuggestions(state, searchUseCasesSuggestions) {
            searchUseCasesSuggestions = searchUseCasesSuggestions.sort((a: UseCase, b: UseCase) =>
            a.name > b.name ? 1 : -1);
            state.searchUseCasesSuggestions = searchUseCasesSuggestions;
        },
        searchIdeasSuggestions(state, searchIdeasSuggestions) {
            searchIdeasSuggestions = searchIdeasSuggestions.sort((a: IdeasSuggestion, b: IdeasSuggestion) =>
            a.title > b.title ? 1 : -1);
            state.searchIdeasSuggestions = searchIdeasSuggestions;
        },
        tabs(state, tabs) {
            state.tabs = tabs;
        },
        setSearchString() {
            //
        },
        categories(state, categories) {
            state.categories = categories;
        },
        technologies(state, technologies) {
            state.technologies = technologies;
        },
        user(state, user) {
            state.user = user;
        },
        userGroups(state, groups) {
            state.userGroups = groups;
        },
    },
    actions: {
    },
    getters: {
        isLoading(state) {
            return state.isLoading;
        },
        isLoadingSuggestions(state) {
            return state.isLoadingSuggestions.length > 0;
        },
        useCases(state) {
            return state.useCases;
        },
        reports(state) {
            return state.reports;
        },
        ideas(state) {
            return state.ideas;
        },
        applications(state) {
            return state.applications;
        },
        searchSuggestions(state) {
            return ([] as SearchResult[])
                .concat(
                    state.searchUseCasesSuggestions as SearchResult[],
                    state.searchIdeasSuggestions as SearchResult[],
                ).map((suggestion: SearchResult) => ({
                    ...suggestion as SearchResult,
                    label: suggestion.coodeId
                        ? `[DiT] ${suggestion.title}`
                        : suggestion.applicationType === ApplicationType[ApplicationType.ReportApplication] ?
                        `[R] ${suggestion.name}` : suggestion.name || suggestion.title,
                    value: `${suggestion.id}`,
                }));
        },
        searchIdeasSuggestions(state) {
            return state.searchIdeasSuggestions;
        },
        tabs(state) {
            return state.tabs;
        },
        categories(state) {
            return state.categories;
        },
        technologies(state) {
            return state.technologies;
        },
        user(state) {
            return state.user;
        },
        userGroups(state) {
            return state.userGroups?.sort((a, b) => a.name.localeCompare(b.name))
        },
    },
    modules: {
        notifications,
    },
});
