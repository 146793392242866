<template>
    <div class="header">
        <adam-header-bar
            v-if="notificationsLoaded"
            :username="user.name"
            :emailaddress="user.email"
            :showNotifications.prop="showNotifications"
            :notifications.prop="mappedNotifications"
            :class="[
                'header-bar',
                { 'bg-dev': isDevelopmentEnv },
                { 'bg-uat': isUatEnv },
            ]"
            @apps-click="clickDrawer"
            @notification-click="goNotificationOverviewRoute"
            @notification-approve-click="handleApprove"
            @notification-reject-click="handleReject"
            @notification-redirect-click="handleRedirect"
            @view-history-click="goNotificationOverviewRoute"
        >
            <span slot="headerContent" @click="goHomeRoute()">
                <div class="logo d-none-mobile">
                    <img src="~adam.ui-core/assets/brand/logo-coode-white.svg"/>
                </div>
                <div class="ml-1 d-none-mobile">
                    {{$t('ideasToLife')}}
                </div>
            </span>
            <span slot="profileDetails">
                <button type="button" @click="goToFeedbackForm" class="feedback-button mb-1">
                  {{$t('sendFeedback')}}
                </button>
                <button type="button" @click="logOut()" class="logout-button" ref="logout-button">
                    {{$t('signOut')}}
                </button>
            </span>
            <span slot="notificationsHeader" class="notification-header">
                <pui-context-menu class="notification-header__context-menu">
                    <pui-context-menu-item
                        :key="0"
                        @click.native="clearAllNotifications"
                        :disabled="false"
                        :label="$t('notificationHeaderContextMenu.clearAll')"
                        class="notification-header__context-menu__item"
                    >
                        <pui-icon slot="default" icon-name="delete" size="48px" class="notification-header__context-menu__item--icon"></pui-icon>
                    </pui-context-menu-item>
                    <pui-context-menu-item
                        :key="1"
                        :disabled="true"
                        :label="$t('notificationHeaderContextMenu.settings')"
                        class="notification-header__context-menu__item"
                    >
                        <pui-icon slot="default" icon-name="settings" size="48px" class="notification-header__context-menu__item--icon"></pui-icon>
                    </pui-context-menu-item>
                    <pui-context-menu-item
                        :key="2"
                        @click.native="goNotificationOverviewRoute"
                        :disabled="false"
                        :label="$t('notificationHeaderContextMenu.open')"
                        class="notification-header__context-menu__item"
                    >
                        <pui-icon slot="default" icon-name="folder-open" size="48px" class="notification-header__context-menu__item--icon"></pui-icon>
                    </pui-context-menu-item>
                </pui-context-menu>
            </span>
        </adam-header-bar>
        <app-drawer
            v-if="applications"
            ref="appDrawer"
            :data.prop="getApps"
            :labels.prop="labelsAppDrawer"
            :onClick.prop="clickApp">
            <span slot="appLogo" class="app-logo">
                <img src="~adam.ui-core/assets/brand/logo-coode-rgb.svg" />
            </span>
        </app-drawer>
    </div>
</template>

<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import { AppService, NotificationService, UseCaseService } from '@/services';
import { EventBus } from '@/utils/eventbus';
import { mapNotification } from '@/utils/mappers/notificationMapper';
import { Application, Notification } from '@/models';
import AppDrawer, { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { Action } from '@/models/notification';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';
import MessageHandler from '@/utils/message-handler';
import sdk from '@/utils/fe-sdk/sdk';

interface Profile {
    given_name: string;
    family_name: string;
    sub: string;
    userId: number;
    userKid: string;
}

@Component({
    name: 'ui-header',
})
export default class Header extends Vue {
    private user = {
        name: '',
        email: '',
        userId: 0,
        userKid: '',
    };
    private appService: AppService = new AppService();
    private notificationService: NotificationService = new NotificationService();
    private useCaseService: UseCaseService = new UseCaseService();
    private applications: Application[] = [];
    private notificationsLoaded = false;

    private async getNotifications() {
        try {
            this.$store.commit('loading', true);
            this.notificationsLoaded = false;
            const notifications = (await this.notificationService.getAllNotifications(
                {
                    ListNotifications: true,
                    ListTasks: true,
                    GetRead: false,
                    page: 1,
                    size: 99,
                    term: '',
                })).result.items;

            if (!this.$store.getters.useCases || this.$store.getters.useCases.length === 0) {
                const useCases = (await this.useCaseService.getAllUseCases()).result.items;
                this.$store.commit('useCases', useCases);
            }
            this.$store.commit('notifications/setHeaderNotificationsMapped',
                this.getMappedNotifications(notifications));
            this.notificationsLoaded = true;
        } catch (err) {
              MessageHandler.showToastMessage(
                this.$t('notificationsNotLoaded') as string,
                ToastMessageTypes.ERROR
              );
            throw err;
        } finally {
            this.$store.commit('loading', false);
        }
    }

    private getMappedNotifications(notifications: Notification[]): any[] {
        const mappedNotifications: any[] = [];
        const useCases = this.$store.getters.useCases || [];

        if (notifications && useCases) {
            notifications.filter(n => n.useCaseId === Number(process.env.VUE_APP_USE_CASE_ID)).forEach((n: any) => {
                mappedNotifications.push(mapNotification(n, useCases));
            });
        }
        return mappedNotifications;
    }

    get mappedNotifications(): any[] {
        return this.$store.getters['notifications/headerNotificationsMapped'] || [];
    }

    get showNotifications(): boolean {
        return this.$store.getters['notifications/showNotifications'];
    }

    private async mounted(): Promise<void> {
        await this.initialize();
    }

    private async initialize(): Promise<void> {
        const profile: any = sdk?.auth.getAccount()

        if (profile) {
            this.user.name = profile?.name;
            this.user.email = profile?.username;
            this.user.userKid = profile?.username.split('@')[0];
            await this.getApplication();
            await this.getNotifications();
            this.$store.commit('user', this.user);
        }
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch (e) {
            return 'app-generic';
        }
    }

    get getApps() {
        return this.applications.map((app: Application): MenuEntry => ({
            id: app.useCaseId,
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.getLogo(app.logo),
            url: app.url ? app.url : undefined,
        })).sort((a, b) => {
            const nameA = a.label.toLowerCase();
            const nameB = b.label.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    get labelsAppDrawer() {
        return { applications: this.$i18n.t('appsWithAccess'), miscellaneous: this.$i18n.t('appsWithoutAccess') };
    }

    private clickApp(app: Application) {
        if (app.url) {
             window.open(app.url);
        }
    }

    private async getApplication() {
        this.$store.commit('loading', true);
        try {
            // temporary fix, hide UAM & Repository
            this.applications = (await this.appService.getApps()).result.items
                .filter((a: Application) => Number(a.useCaseId) !== 3 && Number(a.useCaseId) !== 4);
            this.$store.commit('applications', this.applications);
        } catch (err) {
            MessageHandler.showToastMessage(
              this.$t('errorGettingData') as string,
              ToastMessageTypes.ERROR
            );
            throw err;
        } finally {
            this.$store.commit('loading', false);
        }
    }

    private goHomeRoute() {
        if (this.$route.path !== '/' || this.$route.params.moduleName) {
            this.$router.push({name : 'MainPage'});
        }
    }

    private goToFeedbackForm() {
      this.$router.push({
            name: 'ContactPage',
            params: {
                useCaseId: String(process.env.VUE_APP_USE_CASE_ID),
            },
            query: {
                isFeedback: 'true',
            },
        });
    }

    private goNotificationOverviewRoute(event: any) {
      let tab = 0;
      if (event.detail.notification) {
          tab = event.detail.notification.actions ? 0 : 1;
      }
      this.$router.replace({name : 'NotificationsOverviewPage', query: {tab: tab.toString()}});
      this.$router.go(0);
    }

    private clickDrawer() {
        (this.$refs.appDrawer as AppDrawer).open = true;
    }

    private logOut(): void {
        sdk?.auth.logout();
    }

    private handleApprove(event: any): void {
        const notification = this.mappedNotifications
            .find((n: Notification) => n.id === event.detail.id)?.notification;
        this.handleAction(notification, 0);
    }

    private handleReject(event: any): void {
        const notification = this.mappedNotifications
            .find((n: Notification) => n.id === event.detail.id)?.notification;
        this.handleAction(notification, 1);
    }

    private handleRedirect(event: any): void {
        const notificationId = event.detail.notification.id;
        this.$store.dispatch(
            'notifications/markNotificationAsRead',
            this.mappedNotifications.find((n: any) => n.id === notificationId));

        this.notificationService.markNotificationAsRead(notificationId);

        window.open(event.detail.redirectUrl);
    }

    private handleAction(notification: any, actionId: number): void {
        if (notification.actions && actionId === 0) {
            const foundCorrespondingAction = notification.actions.find((action: Action) => action.id === actionId);

            if (foundCorrespondingAction.includeColumnIdsInRequest) {
                const content = {} as any;

                foundCorrespondingAction.includeColumnIdsInRequest.forEach((columnId: number) => {
                    const foundDynamicColumn =
                      notification.dynamicColumns.find((column: any) => column.id === columnId);
                    content[columnId.toString()] = foundDynamicColumn.value;
                });

                this.notificationService.doAction(notification.workflowInstanceId, actionId, content);
                this.$store.dispatch('notifications/removeTask', notification);
                return;
            }
        }
        this.notificationService.doAction(notification.workflowInstanceId, actionId);
        this.$store.dispatch('notifications/removeTask', notification);
    }

    private async clearAllNotifications(): Promise<void> {
        await Promise.all([this.notificationService.clearAllNotifications(),
            this.notificationService.markNotificationsAsRead(this.mappedNotifications.map((n) => n.id))]);

        this.mappedNotifications.forEach((n: any) => {
            this.$store.dispatch('notifications/markNotificationAsRead', n?.notification);
        });

        this.getNotifications();
        EventBus.$emit(EventBus.NOTIFICATIONS.RELOAD_SIMPLE_NOTIFICATIONS);
    }

    private created() {
        EventBus.$on(EventBus.NOTIFICATIONS.RELOAD_HEADER_NOTIFICATIONS, () => {
            this.getNotifications();
        });
    }

    private get isDevelopmentEnv(): boolean {
        return ['dev', 'development'].includes(process.env.VUE_APP_ENVIRONMENT as string);
    }

    private get isUatEnv(): boolean {
        return process.env.VUE_APP_ENVIRONMENT === 'uat';
    }
}
</script>

<style scoped lang='less'>
@import '../../variables.less';
.app-logo {
    img {
        height: 100%;
        margin-right: 1rem;
    }
}
.header {
    font-family: Roboto, sans-serif;
    .logout-button {
        height: 3.5rem;
        width: 100%;
        font-size: 1.6rem;
        color: @dark-grey;
        background: @light-grey;
        transition: 0.2s ease-in;
        border-radius: 0;
        &:hover {
            transition: 0.2s ease-in;
            background: #E0E0E0;
        }
    }
    .feedback-button {
        height: 3.5rem;
        width: 100%;
        font-size: 1.6rem;
        color: @white;
        background: @uniper-blue;
        transition: 0.2s ease-in;
        border-radius: 3.2rem;
        &:hover {
            transition: 0.2s ease-in;
            background: @dark-blue;
        }
    }
    .header-title {
       cursor: pointer;
    }
    .logo {
        &, img {
        height: 91%;
        cursor: pointer;
        margin-right: 0.5rem;
        padding: 2px 0 0 3px;
     }
    }
    .menu-icon {
        background-color: #0059a4;
        padding: 1.5rem;
    }
    .border-right {
        border-right: 0.1rem solid white;
        margin: 0 1rem;
        padding-left: 1rem;
        height: 60%;
    }
    @media (max-width: 767.98px) {
        .d-none-mobile {
            display: none;
        }
    }
}

app-drawer {
    ::-webkit-scrollbar-track {
    background: @white;
  }

  ::-webkit-scrollbar {
    width: 0.6rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: @warm-grey;
    background-color: rgba(@warm-grey, 0.5);
    border-radius: 0.3rem;
  }
}

.notification-header {
    margin: 1.5rem 0;
    &__context-menu {
        &__item {
            width: 28rem;
            display: flex;
            flex-direction: row-reverse;
            &--icon {
                width: fit-content;
                padding-right: 0;
            }
        }
    }
}

::v-deep .adam-header__separator.adam-header-bar {
    background: white;
}

::v-deep .adam-header-notifications__icon {
    --stroke: white !important;
}

::v-deep .bg-uat {
    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @uniper-uat;

        .adam-header-user__icon.adam-header-bar path {
            fill:@uniper-uat;
        }

        .adam-header-user__icon--focused.adam-header-bar,
        .adam-header-notifications__icon--focused.adam-header-bar {
            background: @uniper-uat;
        }

        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-notifications__icon.adam-header-bar:hover {
            background: @uniper-uat-darker;
            --stroke: white;
        }

        .adam-header__logo.adam-header-bar:hover {
            --fill: @uniper-uat-darker;
        }
    }
}

::v-deep .bg-dev {
    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @uniper-dev;

        .adam-header-user__icon.adam-header-bar path {
            fill: @uniper-dev;
        }

        .adam-header-user__icon--focused.adam-header-bar,
        .adam-header-notifications__icon--focused.adam-header-bar {
            background: @uniper-dev;
        }

        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-notifications__icon.adam-header-bar:hover {
            background: @uniper-dev-darker;
        }

        .adam-header__logo.adam-header-bar:hover {
            --fill: @uniper-dev-darker;
        }
    }
}
</style>
