<template>
    <div id="app">
        <loader></loader>
        <ui-header/>
        <pui-breadcrumb name="breadcrumb" :links="crumbs" @changed:active-route="onActiveRouteChanged"/>
        <router-view></router-view>
        <snackbar />
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header/header.vue';
import Snackbar from '@/components/snackbar/snackbar.vue';
import Loader from '@/components/loader/loader.vue';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import './main.less';
import ToastMessage from '@/mixins/toast-message/toast-message';
import sdk from '@/utils/fe-sdk/sdk';

@Component({
    name: 'app',
    mixins: [ToastMessage],
    components: {
        uiHeader: Header,
        snackbar: Snackbar,
        loader: Loader,
    },
})
export default class App extends Vue {
    private signalConnection: any = null;
    private appStoreScope = process.env.VUE_APP_APP_STORE_SCOPE ?? '';

    private get crumbs() {
        return this.$route?.meta?.title ? [
            { label: 'Home', href: '/' },
            { label: this.$route.meta.title, href: this.$route.path },
        ] : [];
    }

    private async created(): Promise<void> {
        await this.loadAuth();
    }

    private async loadAuth(): Promise<void> {
        const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
        await sdk?.core.userAccessManagement.setPermissions(useCaseId);
        this.initializeSignalrHub();
    }

    private onActiveRouteChanged(prop: {href: string}) {
        try {
            this.$root.$emit('clearSearchFilters');
            this.$router.push({path: `${prop.href}`});
        } catch (e) {
            console.log('Exception:', e);
        }
    }

    private initializeSignalrHub(): void {
        this.signalConnection = new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_SIGNALR_HUB_URL || 'signalr-hubs', {
                accessTokenFactory: this.getAccessToken,
                withCredentials: true,
            })
            .configureLogging(LogLevel.Debug)
            .withAutomaticReconnect()
            .build();

        this.signalConnection.onclose((error: any) => {
            if (error) {
                console.log(`Connection closed with error: ${error}`);
            }
        });

        this.signalConnection.on(
            'Notification',
            (message: any) => {
              const {useCaseId} = message;
                if (useCaseId === process.env.VUE_APP_USE_CASE_ID) {
                  this.$store.dispatch('notifications/addNotification', message);
                }
            });

        this.signalConnection.start()
            .then(() => console.log('Hub connection started'))
            .catch((err: any) => console.log('Error while establishing connection', err));
    }

    private async getAccessToken(): Promise<any> {
        return (await sdk?.auth.getToken(this.appStoreScope));
    }
}
</script>

<style lang="less">
</style>
