<template>
    <div class="flex-row wrapper-boxes">
        <div class="flex-col box-fields">
            <div class="flex-row">
                <div class="flex-col wrapper-info">
                    <div>{{ $t('requestAccessForApplication') }}</div>
                </div>
            </div>
            <div class="flex-row flex-wrap">
                <div class="flex-col flex-1 input-wrapper">
                    <pui-form-radio
                        :label="$t('requestAccessForMe')"
                        :value="accessForMe"
                        :checked="accessForMe"
                        @change="onRadioChange"
                        :isDisabled="userAlreadyHasAccess"
                        class="access-radio"
                    />
                </div>
                <div class="flex-col flex-1 input-wrapper">
                    <pui-form-radio
                        :label="$t('requestAccessForOther')"
                        :value="!accessForMe"
                        :checked="!accessForMe"
                        @change="onRadioChange"
                        class="access-radio"
                    />
                </div>
            </div>
            <div v-if="!accessForMe" class="flex-row flex-wrap">
                <div class="flex-col flex-1 input-wrapper">
                    <label for="user">{{$t('user')}}<span>{{$t('required')}}</span></label>
                    <pui-form-type-ahead
                        v-model="selectedSuggestion"
                        :isLoading="suggestionsLoading"
                        :options="suggestionsList"
                        :max-visible-options="6"
                        search-input-id="userSearchTerm"
                        name="userSearchTerm"
                        :search-input-debounce-time="400"
                        :search-input-placeholder="$t('searchForUserPlaceholder')"
                        :search-input-min-length="2"
                        :has-searchable-secondary-labels="true"
                        label=" "
                        :use-local-cache="true"
                        @search="onSearch"
                        @change="onChange"
                        :required="true"
                        :is-valid="formContact.userId.isInvalid === false"
                        @input="checkInput(formContact.userId)"
                        @blur="checkInput(formContact.userId)"
                    >
                        <template slot-scope="slotProps">
                            <pui-list-option
                                icon-name="circle-input"
                                :label="slotProps.option.label"
                                :secondary-label="slotProps.option.secondaryLabel"
                                :key="slotProps.option.value"
                            />
                        </template>
                    </pui-form-type-ahead>

                </div>
            </div>
            <div class="flex-row flex-wrap">
                <div class="flex-col flex-1 input-wrapper">
                    <label for="usergroup">{{$t('userGroups')}}<span>{{ this.useCaseIsInternal ? $t('required') : ''}}</span></label>
                    <pui-form-multi-select
                        class="input-form"
                        label=" "
                        search-input-id="user-groups-multiselect"
                        v-model="formContact.userGroupIds.value"
                        :options="mappedUserGroupOptions"
                        @change="checkInput(formContact.userGroupIds)"
                        @blur="checkInput(formContact.userGroupIds)"
                        :required="this.useCaseIsInternal"
                        :isValid="formContact.userGroupIds.isInvalid === false"
                    />
                </div>
            </div>
            <div class="flex-row">
                <div class="flex-col full-width input-wrapper">
                    <div class="flex-row justify-space-between">
                      <label for="message">{{$t('message')}}<span>{{$t('required')}}</span></label>
                      <label class="count">{{formContact.message.value.length + '/' + textLengthConstraints.CONTACT_FORM_TEXT_LENGTH}}</label>
                    </div>
                    <pui-form-textarea
                        id="message"
                        v-model="formContact.message.value"
                        class="textarea"
                        :maxLength="textLengthConstraints.CONTACT_FORM_TEXT_LENGTH"
                        @input="checkInput(formContact.message)"
                        @blur="checkInput(formContact.message)"
                        :required="true"
                        :isValid="formContact.message.isInvalid === false"
                    >
                    </pui-form-textarea>
                </div>
            </div>
            <div class="flex-row">
                <div class="flex-col full-width input-wrapper submit-btn">
                    <pui-button
                        :disabled="!formIsSubmittable || isSent"
                        @click="submitForm()">
                        {{$t('submit')}}
                    </pui-button>
                </div>
            </div>
        </div>
        <div class="flex-col box-support">
            <div class="flex-row">
                <div class="flex-col wrapper-info">
                    <div class="title-box">{{$t('support')}}</div>
                    <a class="email-support" href="mailto:coodeteam@uniper.energy">
                        <span>
                            <font-awesome-icon icon="envelope" />
                        </span>
                        {{$t('emailSupport')}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang='ts'>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ModuleApplicationService, UamService } from '../../services';
import { AccessApplicationForm, User, UserGroup, AccessForm, FieldForm, UserFilterModel, SelectOptions } from '../../models';
import TextLengthConstraints from '@/utils/text-length-constraints';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';
import MessageHandler from '@/utils/message-handler';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { LogLevel } from '@coode/fe-sdk';
import sdk from '@/utils/fe-sdk/sdk';

@Component({
    name: 'request-access-internal-app',
})

export default class RequestAccessInternalApp extends Vue {
    /**
     * This prop is required to know the selected usecase ID for further processing.
     */
    @Prop()
    private useCaseId!: string;

    /**
     * This prop is required to know if the user already has access to the selected app.
     * Anyway the user might want to request access for another user.
     */
    @Prop()
    private userAlreadyHasAccess!: boolean;

    private accessForMe = true;
    private selectedSuggestion: any[] = [];
    private suggestionsLoading = false;
    private suggestionsList: any[] = [];
    private useCaseIsInternal = true;
    private textLengthConstraints = TextLengthConstraints;

    private applicationService: ModuleApplicationService = new ModuleApplicationService();
    private uamService: UamService = new UamService();
    private isSent = false;
    private formContact: AccessForm = {
        userId: {
            value: 0,
            type: 'userId',
            isInvalid: false,
        },
        userGroupIds: {
            value: undefined,
            type: 'userGroupIds',
            isInvalid: false,
        },
        request: {
            value: this.$t('requestTrial') as string,
            type: 'request',
            isInvalid: false,
        },
        message: {
            value: '',
            type: 'message',
            isInvalid: false,
        },
    };

    private async created() {
        this.accessForMe = !this.userAlreadyHasAccess;
        this.useCaseIsInternal = this.$route.query.type === 'Internal';
        if (!this.userGroups.length && this.useCaseIsInternal) {
          await this.getUserGroups();
        }
    }

    private async getUserGroups() {
      try {
          const userGroups = (await this.uamService.getUserGroups(Number(this.useCaseId))).result.items;
          this.$store.commit('userGroups', userGroups);
          return userGroups;
      } catch (error) {
          MessageHandler.showToastMessage(
            this.$t('requestingAccessToThisApplication') as string,
            ToastMessageTypes.ERROR
          );
      } finally {
          this.$store.commit('loading', false);
      }
    }

    get users(): User[] {
        return this.$store.getters.users;
    }

    get userGroups(): UserGroup[] {
        return this.$store.getters.userGroups;
    }

    private async submitForm() {
        const isFormValid = Object.values(this.formContact).every((field) => field.isInvalid !== true);
        if (isFormValid) {
            const application: AccessApplicationForm = {
                userId: Number(this.formContact.userId.value),
                userGroupIds: (this.formContact.userGroupIds.value as number[]).map((value) => Number(value)),
                useCaseId: Number(this.useCaseId),
                inquiryType: String(this.formContact.request.value),
                message: String(this.formContact.message.value),
            };

            if (this.accessForMe) {
                delete application.userId;
            }

            try {
                this.$store.commit('loading', true);
                const result = (await this.applicationService.submitAccessAppRequest(application)).result;

                if (!result) {
                    this.isSent = true;
                    MessageHandler.showToastMessage(
                      this.$t('successfully') as string,
                      ToastMessageTypes.SUCCESS
                    );
                    
                    sdk?.logger.logEvent('App access request', {
                        message: 'Submitted Access App Request',
                        useCaseId: Number(process.env.VUE_APP_USE_CASE_ID),
                        severity: SeverityLevel.Information,
                        level: LogLevel.Info,
                        category: 'UserInteraction',
                        type: 'JavaScriptWebApp'
                    });
                }
            } catch (error) {
                if ((error as any).response.data.statusCode === 400 && (error as any).response.data.message) {
                  MessageHandler.showToastMessage(
                    (error as any).response.data.message,
                    ToastMessageTypes.ERROR
                  );
                } else {
                    this.$emit('onRequestAccessError');
                }
            } finally {
                this.$store.commit('loading', false);
            }
        }
    }

    private getSelectedUserKid(): string {
      if (this.selectedSuggestion.length === 0)  {
        return '';
      }
      return this.selectedSuggestion[0]?.secondaryLabel;
    }

    private checkInput(field: FieldForm) {
        this.isSent = false;

        if (/^\s/.test(field.value)) {
            field.value = '';
            return;
        }

        if (field.type === 'userGroupIds') {
            field.isInvalid = !this.validateUserGroupIds();
        }
        if (field.type === 'message') {
            field.isInvalid = !this.validateMessage();
        }
        if (field.type === 'userId') {
            field.isInvalid = this.accessForMe ? false : !this.validateUserId();
        }
    }

    private validateMessage() {
        const message = this.formContact?.message?.value?.toString() || '';
        return message.length && message.length <= 280;
    }

    private validateUserGroupIds() {
        return ((this.formContact?.userGroupIds?.value) as number[])?.length;
    }

    private validateUserId() {
        return !!this.formContact.userId.value;
    }

    private get formIsSubmittable(): boolean {
        let formValues = Object.values(this.formContact);
        formValues = this.accessForMe ? formValues.filter((value) => value.type !== 'userId') : formValues;
        if (!this.useCaseIsInternal) {
          formValues = formValues.filter((value) => value.type !== 'userGroupIds');
        }
        return formValues.every(((value) => value.value))
            && formValues.every((value) => value.isInvalid === false);
    }

    private onRadioChange() {
        this.accessForMe = !this.accessForMe;
    }

    private onChange(item: any[]) {
        if (item && item.length && item[0]) {
            this.formContact.userId.value = item[0].value;
        }
    }

    private async onSearch(searchTerm: string): Promise<void> {
        const userFilterModel: UserFilterModel = {
            page: 1,
            size: 300,
            term: searchTerm,
            sortColumn: 1,
            sortDirection: 1,
        };

        this.suggestionsLoading = true;

        const users = await this.uamService.getUsers(userFilterModel);
        let userList = [];
        userList = users && users.result ? users.result.items : [];

        this.suggestionsList = userList.filter((u: User) => u.id !== this.$store.getters.user.userId)
            .map((u: User) => ({
                label: u.name || u.email || `${u.kid}`,
                email: u.email,
                secondaryLabel: `${u.kid}`,
                value: u.id,
            }));

        this.suggestionsLoading = false;
    }

    private get mappedUserGroupOptions(): SelectOptions {
        return this.userGroups.map((userGroup: UserGroup) => ({ label: userGroup.name, value: `${userGroup.id}` }));
    }
}
</script>

<style scoped lang='less'>
@import '../../variables.less';

.contact-page-container {
    max-width: 125rem;
    margin: 1.6rem auto;
    font-family: Roboto, sans-serif;
    @media screen and (max-width: 699px) {
        margin: 1em 1em;
    }
    h1 {
        margin: 1rem;
    }
    .wrapper-boxes {
        flex-wrap: wrap;
        .input-wrapper {
            margin: 0.8rem;
            min-width: 24rem;
            .access-radio {
                padding-bottom: 0;
            }
        }
        .single-input-col {
            flex: 0 0 48%;
            @media screen and (max-width: 970px) {
                flex: 1;
            }
        }
        .wrapper-info {
            margin: 0.8rem;
        }
        .title-box {
            font-size: 2rem;
            font-weight: bold;
            margin-bottom: 0.2rem;
        }
        .box-fields {
            flex: 2;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 1px 8px 0 rgba(0,0,0,.16);
            padding: 1.6rem;
            margin: 0.8rem;
        }
        .box-support {
            flex: 1;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 1px 8px 0 rgba(0,0,0,.16);
            padding: 1.6rem;
            height: 100%;
            margin: 0.8rem;
            .link-support{
                color: @uniper-blue;
                text-decoration: none;
            }
            .info-support {
                margin: 1rem 0 0 .5rem;
                color: @warm-grey;
            }
            .wrapper-info > div, a {
                padding: 0.32rem 0;
                &.schedule-support {
                    color: @medium-grey;
                    padding-left: 3.2rem;
                }
            }
            span {
                padding: 0.48rem;
                color: @dark-grey;
            }
        }
        label {
            font-size: 1.6rem;
            padding-bottom: 0.8rem;
            span {
                color: @warm-grey;
                padding-left: 0.8rem;
            }
        }
        input.input-form, select.input-form {
            min-height: 12rem;
            font-size: 1.6rem;
            padding: 0.9rem 1.5rem 0.8rem 1.5rem;
            outline: none;
            border: solid 1px #adadad;
            border-radius: 0.4rem;
            &:hover {
                border: solid 1px @uniper-blue;
            }
        }
        .textarea {
            font-size: 1.6rem;
            height: 12rem;
            min-height: 12rem;
            outline: none;
            border-radius: 0.4rem;
            &:hover {
                border: solid 1px @uniper-blue;
            }
        }
        .submit-btn {
            align-items: flex-end;
            .btn {
                color: white;
                background-color: @uniper-blue;
                border-radius: 3.2rem;
                border: 1px solid #0078DC;
                padding: 0.64rem 5.12rem;
                max-width: 16rem;
                text-align: center;
                margin-top: 1.6em;
                cursor: pointer;
                line-height: 1.5;
                &:hover {
                    background-color: @dark-blue;
                }
            }
        }
        .warning-label {
            border: 1px solid red;
            background-color: #ffe7e9;
            option {
                background-color: white;
            }
        }
        .success-sent {
            padding: 1.6rem;
        }
    }
  .count {
      color: @warm-grey;
    }
}
</style>
