import { render, staticRenderFns } from "./contact-page.vue?vue&type=template&id=2d53dd1f&scoped=true"
import script from "./contact-page.vue?vue&type=script&lang=ts"
export * from "./contact-page.vue?vue&type=script&lang=ts"
import style0 from "./contact-page.vue?vue&type=style&index=0&id=2d53dd1f&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d53dd1f",
  null
  
)

export default component.exports