<template>
    <div>
        <div class="search-result-box mt-2" v-if="searchIdeas.length">
            <pui-table
              :vendor-options="tableConfig"
              :withPagination="true"
            >
              <template
                  :slot="tableColumnHeaders.coodeId"
                  slot-scope="{ row }"
              >
                  {{ row.coodeId }}
              </template>
              <template
                  :slot="tableColumnHeaders.title"
                  slot-scope="{ row }"
              >
                  <pui-link
                    :href="getUrl(row.id)"
                    :title="row.title"
                    target="_blank"
                />
              </template>
              <template
                :slot="tableColumnHeaders.description"
                  slot-scope="{ row }"
              >
                  {{ getShortenedDescription(row.description) }}
              </template>
              <template
                :slot="tableColumnHeaders.businessOwner"
                  slot-scope="{ row }"
              >
                  {{ row.businessOwner }}
              </template>
              <template
                :slot="tableColumnHeaders.productOwner"
                  slot-scope="{ row }"
              >
                  {{ row.productOwner }}
              </template>
            </pui-table>
        </div>
        <pui-loader-error
            v-if="!searchIdeas.length && !$store.state.isLoading"
            class="mt-2"
            :title="$t('noSearchResultsTitle')"
            :message="$t('noSearchResultsMessage')"
            icon="error-empty-data"
        />
    </div>
</template>

<script lang='ts'>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IdeasSuggestion, IdeaSuggestionTableItem } from '@/models';
import TextLengthConstraints from '@/utils/text-length-constraints';

@Component({
    name: 'ideas-suggestion',
})
export default class IdeasSuggestions extends Vue {
    @Prop()
    private searchIdeas!: IdeasSuggestion[];

    private textLengthConstraints = TextLengthConstraints;

    private tableColumnHeaders = {
      coodeId: this.$t('table.coodeId') as string,
      title: this.$t('table.title') as string,
      description: this.$t('table.description') as string,
      businessOwner: this.$t('table.businessOwner') as string,
      productOwner: this.$t('table.productOwner') as string,
    };

    private getUrl(id: any) {
      return `${process.env.VUE_APP_DIGITAL_IDEA_TRACKER_UI_BASE_URL}#/ideas/${id}`;
    }

    get tableConfig(): any {
        return {
            options: {
                sortable: [],
                perPage: 10,
            },
            columns: Object.values(this.tableColumnHeaders),
            data: this.ideasResults,
        };
    }

    get ideasResults(): IdeaSuggestionTableItem[] {
      return this.searchIdeas?.map((idea: any) => new IdeaSuggestionTableItem(idea) ?? []);
    }

    private getShortenedDescription(description: string): string {
      if (description.length > this.textLengthConstraints.DESCRIPTION_TEXT_LENGTH) {
        return description.slice(0, this.textLengthConstraints.DESCRIPTION_TEXT_LENGTH) + '...';
      }
      return description;
    }
}
</script>

<style lang="less">
@import '../../../variables.less';

.search-result {
    &-box {
        display: flex;
        flex-direction: column;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    &-idea-header {
        color: @dark-grey;
        background-color: @light-grey;
        padding: 1.5rem;
        font-size: 1.4rem;
        font-weight: bold;
        border-bottom: 0.1rem solid @grey-lighter;
    }

    &-idea-item {
        display: flex;
        padding: 2rem 2.5rem;
        background: @white;
        border-bottom: 0.1rem solid @grey-lighter;
        cursor: pointer;
        // flex-direction: column;
    }

    &-idea-id {
        display: flex;
        width: 9%;
        align-items: center;
        color: @dark-grey-darker;
        font-size: 1rem;
        font-weight: bold;
        margin-right: 1rem;
    }

    &-idea-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // display: flex;
        width: 50%;
        align-items: center;
        color: @uniper-blue;
        font-size: 1.4rem;
        font-weight: bold;
    }
    &-idea-description  {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 1.4rem;
        width: 50%;
        font-size: 1.2rem;
    }
    &-idea-misc  {
        height: 1.4rem;
        width: 20%;
        font-size: 1.2rem;
        margin-left: 1rem;

        strong {
            font-weight: 700;
            color: @uniper-blue;
        }
    }

    .pui-table .VueTables__table thead th:nth-child(2) {
      width: 50rem;
    }
}
</style>
